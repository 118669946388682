<template>
  <v-row class="pa-4" v-if="blogs.length>0">
    <v-col class="col-12">
      <p class="text-h4 pt-6 pb-0 mb-0"> BLOGS </p>
    </v-col>
  <v-col v-for="(post,index) in blogs" :key="'p'+index" cols="12" md="4" sm="6">
    <BlogCard :post="post" />
  </v-col>
    <v-col class="col-12" justify-content="center" v-if="blogs && pagination.current < pagination.total">
      <v-btn class="loadMoreBlogs" @click="getBlogs" x-large block>  Load More </v-btn>
    </v-col>
  </v-row>

  <div class="col-12 empty-data-container" v-else>

    <div class="d-flex justify-center">

      <div class="no-image-container">
        <v-img src="@/assets/img/not-found.png" cover/>
      </div>

    </div>

    <p class="text-h5 text-center">
      No blogs found
    </p>

  </div>



</template>

<script>
import { mapState } from "vuex";
import BlogCard from "@/components/BlogCard";
import store from "@/store";
export default {
  name: "Blog",
  metaInfo(){
    return{
      title:'Blogs',
      titleTemplate:this.$root.$options.titleTemplate,
    }
  },

  components: {
    BlogCard,
  },
  data() {
    return {
      loaded:false,
      categories: [],
      selectedCategory: null,
      pagination: {
        current: 1,
        total: 0,
        totalVisible:7,
      },
    };
  },
  created() {
  },

  mounted() {
    this.pagination.current = this.blogPage;
  },
  computed: {
    ...mapState(["blogs",'blogPage']),
  },

  methods: {
      getBlogs(){
        store.dispatch('fetchAllBlogs', this.blogPage+1).then(response =>{
          this.pagination.current = response.current_page;
          this.pagination.total = response.last_page;
        });
        }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Titillium+Web&display=swap");
.blog-heading {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
}

.blogs {
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
}

.loadMoreBlogs{

  background-color: rgb(60, 201, 236) !important;
  color: white !important;
}
</style>
