<template>
  <v-container fluid class="blog-post" v-if="currentBlog">
    <v-row>

      <v-col class="col-md-2 col-12" v-if="$vuetify.breakpoint.mdAndUp">
        <div class="b-left-content ">
          <div>
            <v-icon>mdi-eye</v-icon>
            <p>
              {{ currentBlog.views }} views
            </p>
          </div>
          <div>
            <v-icon
                @click.stop
                :class="currentBlog.isLiked?'liked':'like'"
                @click.self.prevent="changeLikeStatus">

              {{icon(currentBlog.isLiked)}}
            </v-icon>
            <p>
              {{ currentBlog.likes }} likes
            </p>
          </div>
          <p class="poppins">Share</p>
          <v-icon @click="shareOnFacebook" class="social-sharing-icon">mdi-facebook</v-icon> <br>
          <v-icon @click="shareOnTwitter" class="social-sharing-icon">mdi-twitter</v-icon>
        </div>
      </v-col>

      <v-col class="col-md-8">
   <article>
     <v-card>
       <v-img
           class="white--text align-end"
           height="500"
           :src="currentBlog.banner?currentBlog.banner:'https://images.pexels.com/photos/439391/pexels-photo-439391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'"
       >
       </v-img>



       <v-card-title>
         <div class="blog-post-titles">{{ currentBlog.title }}</div>
       </v-card-title>

       <v-card-subtitle>
         <v-row justify="space-between" >
           <v-col class="published-col" v-if="currentBlog.author.name"> By: {{ currentBlog.author.name }} </v-col>
           <v-col class="published-col">
             <v-icon class="mr-1 blog-view-post-icon">
               mdi-clock-time-five-outline</v-icon>
             {{ currentBlog.created_at_human }}
           </v-col>
           <v-col class="published-col">
             <v-icon class="mr-1 blog-view-post-icon">mdi-eye</v-icon>
             {{ currentBlog.views }} Views
           </v-col>
         </v-row>
       </v-card-subtitle>

       <v-card-text v-if="currentBlog.categories.length >0">
<!--         <h2 class="text-h6 mb-2">Category</h2>-->
         <v-chip
             color="secondary"
             v-for="(category,index) in currentBlog.categories"
             :key="index"
             :to="{
                      name: 'blogCategory',
                      params: { slug: category.slug }
                    }"
             class="mr-2"
         >
           {{ category.name }}
         </v-chip>
       </v-card-text>
       <v-card-text v-if="currentBlog.hashtags.length > 0">
<!--         <h2 class="text-h6 mb-2">Hashtags</h2>-->
         <v-chip
             color="primary"
             v-for="(hashtag,index) in currentBlog.hashtags"
             :key="index"
             :to="{ name: 'blogHashTag', params: { hashtag: hashtag.name.replace('#', '') } }"
             class="mr-2"
         >
           {{ hashtag.name }}
         </v-chip>
       </v-card-text>


       <v-card-text>
         <div class="blog-body" v-html="currentBlog.body"></div>
       </v-card-text>


       <v-card-actions>
         <router-link :to="{name:'blogs'}"
         ><v-btn elevation="2" color="#367770" dark
         ><v-icon dark> mdi-arrow-left </v-icon>Back to Blog</v-btn
         ></router-link
         >
       </v-card-actions>
     </v-card>
   </article>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState} from "vuex";
export default {

  metaInfo(){
    return{
      title:this.currentBlog.title,
      titleTemplate:this.$root.$options.titleTemplate,
      meta: [
        {
          name: 'title',
          content: this.currentBlog.body,
        },
        {
          name: 'description',
          content: this.currentBlog.body,
        },
        {
          property: 'og:title',
          content: this.currentBlog.title,
        },
        {
          property: 'og:description',
          content: this.stripHtmlTags(this.currentBlog.body),
        },
        {
          property: 'og:image',
          content: this.currentBlog.banner,
        },
        {
          property: 'og:url',
          content: window.location.href,
        },
        {
          property: 'og:type',
          content: 'article',
        },
        {
          property:'og:site_name',
          content: new URL(window.location.href).hostname,
        },
        //Twitter
        {
          property:'twitter:card',
          content:this.currentBlog.banner,
        },
        {
          property:'twitter:title',
          content:this.currentBlog.title,
        },
        {
          property:'twitter:description',
          content: this.stripHtmlTags(this.currentBlog.body),
        },
        {
          property:'twitter:image',
          content: this.currentBlog.banner,
        },
        {
          property:'twitter:site',
          content: new URL(window.location.href).hostname,
        },
        {
          property: 'twitter:url',
          content: new URL(window.location.href).hostname,
        },
      ],
    }
  },
  data() {
    return {
      post: {},
    };
  },
  computed: {
    ...mapState(['currentBlog']),
    stripHtmlTags() {
      return (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
      };
    },
    icon() {
      return (status) => {
        return status ? `mdi-heart` : "mdi-heart-outline";
      };
    },

  },
  watch:{
    currentBlog(){
    this.currentBlog.isLiked = false;
    }
  },

  mounted() {
    window.setTimeout(()=>{
      this.$store.dispatch('increaseViewBlog',this.currentBlog.id);
    },4000);


  },
  methods: {
    changeLikeStatus() {
      if (!this.currentBlog.isLiked){
        try {
          this.$store.dispatch('increaseLikeBlog',this.currentBlog.id);
          ++this.currentBlog.likes;
          this.currentBlog.isLiked = !this.currentBlog.isLiked;
        } catch (error) {
          console.error(error);
        }

      }

    },

    shareOnTwitter(){
      // this.$axios.post('/post/increment-twitter-share',{id:this.currentBlog.id});
      const tweetText = encodeURIComponent(this.currentBlog.title);
      const tweetURL = window.location.href; // Replace with your blog post URL
      const url = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetURL}`;
      window.open(url, '_blank', 'width=550,height=350');
    },
    shareOnFacebook() {
      // this.$axios.post('/post/increment-fb-share',{id:this.currentBlog.id});
      const shareURL = window.location.href;
      const fbShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareURL)}`;
      window.open(fbShareURL, '_blank', 'width=550,height=350');
    },

  },
};
</script>
<style>



</style>
