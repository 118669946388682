<template>
  <v-container fluid>

    <CoolLightBox
        :items="currentProperty.images"
        :index="index"
        @close="index = null">
    </CoolLightBox>


    <v-row>
      <v-col class="col-12 padding-default">
        <v-row>
          <v-col class="col-md-9 col-12">
            <v-row justify="center">
              <v-col class="col-md-10 col-12 pb-0">
              <span class="property-code">
                 {{ currentProperty.code }}
              </span>
                <span class="property-for">
                  <span v-if="currentProperty.for !== 'sold'">
                  For
                  </span>
                {{ currentProperty.for }}
                  <v-icon v-if="currentProperty.is_premium != '0'" class="overlay-crown">mdi-crown</v-icon>
              </span>

                <span class="sharer-icon ml-3 pa-2 "  @click="shareModal = !shareModal" v-if="$vuetify.breakpoint.smAndDown">
                  <v-icon class="share-icon"> mdi-share-circle</v-icon>Share
                </span>


                <p class="s-property-title" :title="currentProperty.title">
                  {{ currentProperty.name }}
                </p>
                <p class="s-p-location" :title="currentProperty.location">
                  <v-icon class="pin-icon"> mdi-map-marker</v-icon>
                  {{ currentProperty.location }}
                </p>
              </v-col>

              <v-col class="col-2 dark d-flex align-center" style="justify-content: right" v-if="$vuetify.breakpoint.mdAndUp">
                  <p class="sharer-icon  float-right"  @click="shareModal = !shareModal">
                                  <v-icon class="share-icon"> mdi-share-circle</v-icon>Share
                  </p>

              </v-col>


              <v-dialog
                  v-model="shareModal"
                  width="590px"
              >
                <v-card>

                  <v-card-title>
                    <p class="mb-0"> Share </p>
                  </v-card-title>
                  <v-card-text class="px-4">

                    <v-row>
                      <v-col class="col-12">

                        <v-icon @click="shareOnFacebook" class="social-sharing-icon">mdi-facebook</v-icon>
                        <v-icon @click="shareOnTwitter" class="social-sharing-icon">mdi-twitter</v-icon>
                        <v-icon @click="shareToWhatsApp" class="social-sharing-icon">mdi-whatsapp</v-icon>
                        <v-icon @click="shareToReddit" class="social-sharing-icon">mdi-reddit</v-icon>
                        <v-icon @click="shareToEmail" class="social-sharing-icon">mdi-email</v-icon>

                      </v-col>

                      <v-col class="col-12">
                        <v-text-field :value="getCurrentUrl" readonly solo-inverted id="copyInput">
                          <template #append>
                            <v-btn solo class="clipboard-copy-btn"
                                   @click="doCopy"
                                   large
                            > Copy </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>

                  </v-card-text>
                  <v-card-text class="pa-3">


                  </v-card-text>

                </v-card>
              </v-dialog>


              <v-col class="col-md-12 col-12 pt-0 mt-0 s-p-slick-container" v-if="currentProperty.images.length>0">
                <slick ref="c1" v-bind="c1Setting" v-if="currentProperty.images.length >= 1"
                       :key="'y'+currentProperty.id"
                >

                  <template v-for="(slide, n) in currentProperty.images">
                    <div
                        class="large-slick-display"
                        :key="'gg'+n"
                        :style="$inlineBgImage(slide)"
                        @click="index = n"
                    ></div>
                  </template>

                </slick>

                <v-row justify="center">
                  <slick
                      ref="c2"
                      class="col-md-12 col-12"
                      v-bind="c2Setting"
                      v-show="currentProperty.images.length >= 2"
                      :key="'x'+currentProperty.id"
                      @beforeChange="onBeforeChangeC1"
                  >
                    <template #prevArrow>
                      <div class="custom-arrow">
                        <v-icon>mdi mdi-arrow-left-circle</v-icon>
                      </div>
                    </template>

                    <template #nextArrow>
                      <div class="custom-arrow">
                        <v-icon>mdi mdi-arrow-right-circle</v-icon>
                      </div>
                    </template>

                    <template v-for="(slide, n) in  currentProperty.images">
                      <div
                          class="small-slick-display"
                          :key="'sm'+n"
                          :style="$inlineBgImage(slide)"
                      ></div>
                    </template>
                  </slick>
                </v-row>

              </v-col>


              <v-col class="col-12 s-p-content">

                <v-row>
                  <v-col class="col-12">
                    <p class="s-p-content-title">Jump To Sections</p>

                    <v-btn class="skroll" @click="cScroll('facilities','center')"
                           v-if="currentProperty.facilities.length>0"> 
                           <v-icon>mdi-shape-plus</v-icon>Facilities
                    </v-btn>
                    <v-btn class="skroll" v-if="$vuetify.breakpoint.smAndDown" @click="cScroll('features','center')">
                      Features
                    </v-btn>
                    <v-btn class="skroll" @click="cScroll('details','start')">
                           <v-icon>mdi-information</v-icon>About
                    </v-btn>
                    <v-btn class="skroll" @click="cScroll('videos','start')"
                           v-if="currentProperty.youtube_link || currentProperty.tiktok_link">
                           <v-icon>mdi-video</v-icon>Videos
                    </v-btn>
                    <v-btn class="skroll" @click="cScroll('map','start')"
                           v-if="currentProperty.location || currentProperty.city">
                           <v-icon>mdi-map</v-icon>Map
                    </v-btn>
                  </v-col>
                </v-row>

              </v-col>


              <v-col class="col-11 s-p-box s-p-price " v-if="$vuetify.breakpoint.smAndDown"
                     @click="toggleFormat  = !toggleFormat">
                <p class="rs-p-title">
                  Price
                </p>
                <p class="rs-p-price">
                  {{ price }}
                </p>

              </v-col>


              <v-col class="col-12 s-p-content" ref="features">
                <p class="s-p-content-title"> Features</p>

                <v-row>
                  <template v-for="(feature,n) in currentProperty.features">

                    <v-col class="pa-1 float-left" :cols="$vuetify.breakpoint.smAndDown?'4':'2'" :key="n">
                      <div class="s-p-features py-5">
                        <img :src="feature.icon" class="col-4 pa-0 s-p-icon" />
                        <p class="s-p-feature-key">{{ feature.name }}</p>
                        <p v-if="feature.value" class="s-p-feature-value">
                          {{ feature.value }}
                        </p>

                      </div>
                    </v-col>

                  </template>
                </v-row>

              </v-col>


              <v-col class="col-12  s-p-content" ref="facilities" v-if="currentProperty.facilities.length>0">
                <p class="s-p-content-title"> Facilities</p>

                <v-row>
                  <template v-for="(facility,n) in currentProperty.facilities">

                    <v-col class="pa-1  float-left" :cols="$vuetify.breakpoint.smAndDown?'4':'2'" :key="n">
                      <div class="s-p-features py-5">
                        <img :src="facility.icon" class="s-p-icon" />
                        <p class="s-p-feature-key">{{ facility.name }}</p>

                      </div>
                    </v-col>

                  </template>
                </v-row>

              </v-col>


              <v-col class="col-12 s-p-content" style="position: relative;">
                <div ref="details"
                     style="position:absolute; top: -100px;left:0;height: 1px; background: red;width: 1px"/>
                <p class="s-p-content-title"> About The Property</p>

                <div class="col-12" v-html="currentProperty.description"></div>
              </v-col>

              <v-col class="col-12" style="position: relative;" v-if="currentProperty.youtube_link || currentProperty.tiktok_link">
                <div ref="videos"
                     style="position:absolute; top: -100px;left:0;height: 1px; background: red;width: 1px"/>
                <p class="s-p-content-title"> Videos</p>

                <v-row>
                  <v-col class="col-12" v-if="currentProperty.youtube_link">

                    <v-row>


                      <v-col class="col-12">
                        <iframe
                            width="100%"
                            height="530px"

                            :src="currentProperty.youtube_link"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                      </v-col>
                    </v-row>
                  </v-col>


                  <v-col class="col-12" v-if="currentProperty.tiktok_link" v-show="$vuetify.breakpoint.smAndDown">
                    <v-row>
                      <v-col class="col-12">
                        <iframe
                            width="350px"
                            height="765px"
                            style="border:none"
                            :src="currentProperty.tiktok_link"
                            title="Tiktok video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                      </v-col>
                    </v-row>
                  </v-col>

                </v-row>
              </v-col>

        
              <v-col class="col-12 s-p-content" style="position: relative;">
                <div ref="map"
                     style="position:absolute; top: -100px;left:0;height: 1px; background: red;width: 1px"/>
                <p class="s-p-content-title">Map</p>

                <iframe
                  width="100%"
                  height="450"
                  style="border:0"
                  loading="lazy"
                  allowfullscreen
                  referrerpolicy="no-referrer-when-downgrade"
                  :src="googleMapsSrc">
                </iframe>
              </v-col>

            </v-row>
          </v-col>


          <v-col class="col-md-3 col-12 pa-7 pt-2">
            <v-row>
              <v-col class="col-12 s-p-box s-p-price" v-if="$vuetify.breakpoint.mdAndUp"
                     @click="toggleFormat = !toggleFormat">
                <p class="rs-p-title mb-0">
                  Price
                </p>
                <p class="rs-p-price mb-0">
                  {{ price }}
                </p>

              </v-col>

              <v-col class="col-12 pa-0 s-p-box">
                <iframe
                    width="100%"
                    height="575px"
                    class="tiktok-frame"
                    style="border:none;overflow: hidden"
                    v-if="$vuetify.breakpoint.mdAndUp"
                    :src="currentProperty.tiktok_link"
                    title="Tiktok video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>
              </v-col>


<!--              <v-col class="col-12 s-p-box rs-p-features" v-if="$vuetify.breakpoint.mdAndUp">-->
<!--                <p class="rs-p-title">-->
<!--                  Features-->
<!--                </p>-->

<!--                <v-row>-->
<!--                  <template v-for="(feature,n) in currentProperty.features">-->

<!--                    <v-col class="  " :cols="$vuetify.breakpoint.smAndDown?'6':'6'" :key="n">-->
<!--                        <v-row style="max-width: 100%;">-->
<!--                        <v-col class="col-12 ma-2 rs-p-feature" :title="feature.name">-->
<!--                          <v-row>-->
<!--                            <div class="col-3 pa-0 pl-3">-->
<!--                              <img :src="feature.icon" :alt="feature.icon" height="100%" width="100%">-->
<!--                            </div>-->
<!--                            <div class="col-9">-->
<!--                              <p class="s-p-feature-key">{{ feature.name }}</p>-->
<!--                              <p v-if="feature.value" class="s-p-feature-value" :title="feature.value">-->

<!--                                {{ feature.value.length > 8 ? feature.value.slice(0, 8) + '..' : feature.value }}-->


<!--                                &lt;!&ndash;                                {{ feature.value.slice(0,6) +'..' }}&ndash;&gt;-->
<!--                              </p>-->
<!--                            </div>-->
<!--                          </v-row>-->
<!--                        </v-col>-->

<!--                      </v-row>-->

<!--                    </v-col>-->

<!--                  </template>-->

<!--                </v-row>-->
<!--              </v-col>-->


              <v-col class="col-xs-10 col-12 s-p-box px-5">
                <v-row>
                  <v-col class="col-12">
                    <p class="rs-p-title black-color">
                      Contact Details
                    </p>
                    <p class="rs-p-subtitle mb-0 pl-md-2">
                      Mr. Ramesh Barudi
                    </p>
                  </v-col>

                  <v-col class="col-md-12  col-12">
                    <v-row justify="center">
                      <v-col cols="auto" class="pa-0 ma-0">

                        <template v-for="(soc,i) in socials">
                          <template v-if="soc.link">
                            <a :href="soc.link" target="_blank" :key="i">
                              <div class="footer-icons" :key="i">
                                <v-icon class="f-s-icons s-icons" :class="soc.icon.replace('mdi-','')"
                                        v-if="soc.icon !== 'mdi-tiktok' && soc.icon !== 'mdi-instagram' && soc.icon !== 'mdi-whatsapp'  && soc.icon !== 'mdi-linkedin' ">
                                  {{ soc.icon }}
                                </v-icon>
                                <v-img src="@/assets/img/whatsapp.png" v-if="soc.icon === 'mdi-whatsapp'" alt=""
                                       class="socc-icon whatsapp s-icons" contain/>
                                <v-img src="@/assets/img/linkedin.png" v-if="soc.icon === 'mdi-linkedin'" alt=""
                                       class="socc-icon linkedin s-icons" contain/>
                                <v-img src="@/assets/img/instagram.png" v-if="soc.icon === 'mdi-instagram'" alt=""
                                       class="socc-icon instagram s-icons" contain/>
                                <v-img src="@/assets/img/tiktok.png" v-if="soc.icon === 'mdi-tiktok'" alt=""
                                       class="socc-icon tiktok s-icons" contain/>
                              </div>
                            </a>
                          </template>
                          <template v-else>
                            <div class="footer-icons" :key="i">
                              <v-icon class="f-s-icons s-icons" :class="soc.icon.replace('mdi-','')"
                                      v-if="soc.icon !== 'mdi-tiktok' && soc.icon !== 'mdi-instagram' && soc.icon !== 'mdi-whatsapp'  && soc.icon !== 'mdi-whatsapp'">
                                {{ soc.icon }}
                              </v-icon>
                              <v-img src="@/assets/img/whatsapp.png" v-if="soc.icon === 'mdi-whatsapp'" alt=""
                                     class="socc-icon s-icons" contain/>
                              <v-img src="@/assets/img/instagram.png" v-if="soc.icon === 'mdi-instagram'" alt=""
                                     class="socc-icon instagram s-icons" contain/>
                              <v-img src="@/assets/img/tiktok.png" v-if="soc.icon === 'mdi-tiktok'" alt=""
                                     class="socc-icon tiktok s-icons" contain/>
                              <v-img src="@/assets/img/linkedin.png" v-if="soc.icon === 'mdi-linkedin'" alt=""
                                     class="socc-icon linkedin s-icons" contain/>
                            </div>
                          </template>
                        </template>

                      </v-col>

                    </v-row>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <a :href="'tel:'+contact_details.contact" class="ma-0 pa-0">
                          <v-btn class="rs-p-contact" large block> {{ contact_details.contact }}</v-btn>
                        </a>
                      </v-col>

                    </v-row>
                  </v-col>

                  <v-col class="col-12" v-show="false">
                    <p class="rs-p-title black-color">
                      Enquiry About The Property
                    </p>

                    <v-text-field label="Full Name" outlined/>
                    <v-text-field label="Email" outlined/>
                    <v-text-field label="Phone Number" outlined/>
                    <v-textarea label="Message" outlined></v-textarea>
                    <v-btn block class="enq-btn"> Send Enquiry</v-btn>

                  </v-col>

                </v-row>
              </v-col>


              <v-col class="col-12 s-p-box">
                <v-row>
                  <v-col class="col-12">
                    <p class="rs-p-title black-color mb-0 pb-0">
                      Top Listings
                    </p>

                  </v-col>


                  <v-col class="col-12">
                    <template v-for="(property,n) in topListings.slice(0,3)">
                      <router-link :to="{name:'singleProperty',params:{slug:property.slug}}" :key="'top'+n">

                        <div :key="n" class="col-12 s-p-toplisting">
                          <v-row>
                            <v-col class="col-4">
                              <div style="height: 100%; width: 100%" :style="$inlineBgImage(property.images[0])"
                                   class="s-p-toplisting-image">

                              </div>
                            </v-col>
                            <v-col class="col-8 pl-0">
                              <p class="rs-p-tl-title mb-0" :title="property.name">
                                {{ property.name }}
                              </p>
                              <p class="rs-p-tl-location mb-0" :title="property.location">
                                {{property.location}}
                              </p>
                              <div>
                                <span class="rs-p-tl-price"> NPR. {{ $textifyPrice(property.price) }}</span>
                              </div>

                            </v-col>
                          </v-row>
                        </div>
                      </router-link>

                    </template>
                  </v-col>


                </v-row>
              </v-col>


              <v-col class="col-12 s-p-box">
                <v-row>
                  <v-col class="col-12">
                    <p class="rs-p-title black-color mb-0 pb-0">
                      Premium Listings
                    </p>

                  </v-col>


                  <v-col class="col-12">
                    <template v-for="(property,n) in f_premium.slice(0,3)">
                      <router-link :to="{name:'singleProperty',params:{slug:property.slug}}" :key="'top'+n">

                        <div :key="n" class="col-12 s-p-toplisting">
                          <v-row>
                            <v-col class="col-4">
                              <div style="height: 100%; width: 100%" :style="$inlineBgImage(property.images[0])"
                                   class="s-p-toplisting-image">

                              </div>
                            </v-col>
                            <v-col class="col-8 pl-0">
                             <p class="rs-p-tl-title mb-0" :title="property.name">
                              {{property.name }}
                             </p>
                             <p class="rs-p-tl-location mb-0" :title="property.location">
                               {{ property.location}}
                             </p>
                             <div>
                               <span class="rs-p-tl-price"> NPR. {{ $textifyPrice(property.price) }}</span>
                             </div>

                            </v-col>
                          </v-row>
                        </div>
                      </router-link>

                    </template>
                  </v-col>


                </v-row>
              </v-col>



            </v-row>
          </v-col>


        </v-row>

        <v-row v-if="similarProperty.length>0">
          <v-col class="col-12">
            <p class="s-p-content-title"> Similar Properties</p>
            <v-row>
              <div class="col-md-3 col-12 " v-for="(item,n) in similarProperty" :key="'sim'+n">

                <PropertyCard :item="item"/>

              </div>

            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>


</template>

<script>

import {mapState} from "vuex";
import Slick from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import PropertyCard from "@/components/PropertyCard";
import Vue from "vue";


import CoolLightBox from 'vue-cool-lightbox';
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "RevampSingleProperty",
  metaInfo() {
    return {
      title: this.currentProperty.name,
      titleTemplate: this.$root.$options.titleTemplate,
      meta: [
        {
          name: 'title',
          content: this.currentProperty.name,
        },
        {
          name: 'description',
          content: 'Perfect place for property solution',
        },
        {
          property: 'og:title',
          content: this.currentProperty.name,
        },
        {
          property: 'og:description',
          content: 'Perfect place for property solution',
        },
        {
          property: 'og:image',
          content: this.currentProperty.images[0],
        },
        {
          property: 'og:image:alt',
          content: this.currentProperty.name,
        },
        {
          property: 'og:image:height',
          content: 600,
        },
        {
          property: 'og:image:width',
          content: 800,
        },
        {
          property: 'og:url',
          content: window.location.href,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:site_name',
          content: new URL(window.location.href).hostname,
        },
        //Twitter
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:title',
          content: this.currentProperty.name,
        },
        {
          property: 'twitter:description',
          content: this.stripHtmlTags(this.currentProperty.body),
        },
        {
          property: 'twitter:image',
          content: this.currentProperty.images[0],
        },
        {
          property: 'twitter:site',
          content: new URL(window.location.href).hostname,
        },
        {
          property: 'twitter:url',
          content: new URL(window.location.href).hostname,
        },
      ],
    }
  },

  components: {
    Slick, PropertyCard,CoolLightBox
  },
  computed: {
    ...mapState(['currentProperty', 'contact_details', 'f_premium', 'topListings', 'similarProperty']),
    stripHtmlTags() {
      return (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
      };
    },
    googleMapsSrc() {
      const apiKey = "AIzaSyAonLIi-0kw-zqzy1Pi7fnb1XJ1rb0FvjY";
      const location = encodeURIComponent(this.currentProperty.location);
      const city = encodeURIComponent(this.currentProperty.city);
      return `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${location},${city}`;
    },
    price() {
      if (this.toggleFormat) {
        return "NPR. " + this.$textifyPrice(this.currentProperty.price)
      } else {
        return "NPR. " + this.$formatPrice(this.currentProperty.price)
      }

    },
    limitedPremium() {
      if (this.f_premium != null && this.f_premium.length > 0) {
        return this.f_premium.slice(0, 3);
      }
      return null;
    },
    limitedTopLising() {
      if (this.topListings != null && this.topListings.length > 0) {
        return this.topListings.slice(0, 3);
      }
      return null;
    }
  },
  data() {
    return {
      getCurrentUrl:'',
      toggleFormat: true,
      shareModal: false,
      index:'',
      socials: {
        facebook: {
          icon: 'mdi-facebook',
          link: '',
        },
        youtube: {
          icon: 'mdi-youtube',
          link: '',
        },
        whatsapp: {
          icon: 'mdi-whatsapp',
          link: '',
        },
        instagram: {
          icon: 'mdi-instagram',
          link: '',
        },

        linkedin: {
          icon: 'mdi-linkedin',
          link: '',
        },
        tiktok: {
          icon: 'mdi-tiktok',
          link: '',
        },

      },
      c1Setting: {
        arrows: false,
        dots: false,
        asNavFor: this.$refs.c2,
        focusOnSelect: true,
      },
      c2Setting: {
        arrows: true,
        dots: false,
        asNavFor: this.$refs.c1,
        focusOnSelect: true,
        slidesToShow: 4,
        speed: 300,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 4000,
      },
    }
  },
  watch: {
    "$route.params.slug": function () {
      this.$store.dispatch('fetchProperty', this.$route.params.slug).then(async response => {
        Vue.prototype.$Progress.finish();

        await this.$store.dispatch('fetchSimilarProperty');
        if (response.message && response.message === "Property Not Found") {
          await this.$router.push({name: 'error'});
        }

      });

    },
  },
  mounted() {
    this.getCurrentUrl = window.location.href;
    window.setTimeout(() => {
      this.$store.dispatch('increaseViewProperty');
    }, 6000)

    Object.entries(this.contact_details).forEach(([key, value]) => {
      if (Object.prototype.hasOwnProperty.call(this.socials, key)) {
        this.socials[key].link = value;
      }
    });

  },
  methods: {
    onBeforeChangeC1(currentPosition, nextPosition) {
      this.$refs.c1.goTo(nextPosition);
      this.$refs.c2.goTo(nextPosition);
    },
    cScroll(to, where) {
      this.$refs[to].scrollIntoView({
        behavior: "smooth",
        block: where, // scroll to the bottom of the element
        inline: where // keep the element in the viewport
      })
    },

    shareOnTwitter() {
      const tweetText = encodeURIComponent(this.currentProperty.name);
      const tweetURL = window.location.href; // Replace with your blog post URL
      const url = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetURL}`;
      window.open(url, '_blank', 'width=550,height=350');
    },
    shareOnFacebook() {
      const shareURL = window.location.href;
      const fbShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareURL)}`;
      window.open(fbShareURL, '_blank', 'width=550,height=350');
    },
    shareToReddit() {
      const shareUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(this.currentProperty.name)}`;
      window.open(shareUrl, '_blank');
    },
    shareToWhatsApp() {
      const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(this.currentProperty.name)}`;
      window.open(shareUrl, '_blank');
    },
   shareToEmail() {
      const shareUrl = `mailto:?body=Check out this site ${encodeURIComponent(window.location.href)}`;
     window.open(shareUrl, '_blank');

   },
    doCopy() {
      let copyText = document.getElementById("copyInput");
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      navigator.clipboard.writeText(copyText.value);
      this.$notify({
        text: 'Link copied',
        type: 'success',
      })
    }

  },

}
</script>

<style scoped>

.padding-default {
  padding: 20px 3%;
}

.property-code {
  background-color: #00b5e7;
  padding: 8px 24px 8px 24px;
  margin-right: 5px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.property-for {
  background-color: #8E0000;
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.overlay-crown{
  position: absolute;
  right: -10px;
  top: -10px;
  rotate: 45deg;
}


.s-property-title {
  font-family: Roboto;
  font-weight: 600;
  font-size: 24px;
  padding-top: 10px;
  margin-bottom: 0;


  text-overflow: ellipsis; /* will make [...] at the end */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;


}

.s-p-location {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  text-transform: capitalize;
  padding-bottom: 0;
  margin-bottom: 5px;
}

.large-slick-display {
  min-height: 440px;
  background-position: center;
  background-size: cover;
}

.small-slick-display {
  min-height: 120px;
  background-position: center;
  background-size: cover;
  margin-right: 5px !important;
  border: 4px solid white;
}

.s-p-slick-container {
  padding-bottom: 32px;

}

.s-p-content {
  margin-bottom: 20px;
}

.s-p-content-title {
  color: #000;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 30px */
  text-transform: capitalize;
  /*text-align: center;*/
}

.s-p-features {
  margin: 2px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #f5f5f5;
  height: 100%;
}

.s-p-feature-key {
  text-align: center;
  color: #07006B;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
}

.s-p-icon {
  text-align: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.s-p-box {
  border-radius: 8px;
  border: 2px solid #00BCE2;
  background: rgba(255, 255, 255, 0.50);
  backdrop-filter: blur(8px);
  margin-bottom: 20px;
  padding:15px;
}

.s-p-price {
  margin-top: 12px;
  display: inline-flex;
  padding: 14px 11px 11px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
  cursor: pointer;

}

.rs-p-title {
  color: #07006C;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 46.8px */
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.black-color {
  color: black;
}

.rs-p-subtitle {
  color: #07006B;

  /* Heading/H5 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
}


.rs-p-price {
  color: #000;

  /* Heading/H4 */
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 30px */
  text-transform: capitalize;


  margin-top: 0 !important;
  margin-bottom: 5px !important;


}

.rs-p-features {
  padding: 24px !important;
}

.rs-p-feature {
  border-radius: 4px;
  border: 1px solid #07006B;
  background: #f5f5f5;
  text-align: center;
}

.s-p-feature-key {
  font-size: small;
  margin-bottom: 0px;
  text-align: left;
  text-transform: capitalize;
}

.s-p-feature-value {
  margin-bottom: 0px;
  text-align: left;
}

.s-p-feature-icon {
  font-size: 32px !important;
}

.rs-p-company-title {
  color: #000;
  font-family: Roboto;
  font-size: 25px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 30px */
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 0px;
}

.rs-p-company-location {
  color: #787878;
  /* Heading/S */
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
  padding-top: 5px;
  margin-bottom: 3px;
}

.rs-p-company-telephone {
  display: flex;
  padding: 8px;
  align-items: center;

  color: #FFF;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 15.6px */


  border-radius: 4px;
  background: #07006B;
  width: auto;
}


.rs-p-company-telephone .v-icon {
  color: white;
}

.v-icon {
  color: #07006B;
}


.enq-btn {
  background-color: #399ece !important;
  color: white;
}


.s-p-toplisting {
  border-radius: 4px;
  border: 1px solid #DFDFDF;
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
}

.s-p-toplisting-image {
  background-position: center;
  background-size: cover;
  height: 101px !important;
  max-width: 100%;
  overflow: hidden;

}

.rs-p-tl-title {
  color: #000;



  text-overflow: ellipsis; /* will make [...] at the end */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;


  /* Heading/H4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 2.5px;
  line-height: 20px;

}

.rs-p-tl-location {
  color: #000;

  /* Heading/S */
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
  text-transform: capitalize;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rs-p-tl-price {
  position: absolute;
  width: auto !important;
  border-radius: 2px;
  background: #07006B;
  color: white;
  padding: 4px;
  margin-top: 10px !important;
}


.r-facebook-icon {
  max-width: 24px;
}

.rs-social-icons {
  position: relative;
  width: 35px;
  margin-right: 5px;
}

.rs-social-icon {
  position: relative;
  width: 45px;
  margin-top: 10px;
}

.rs-p-contact {
  border-radius: 4px;
  border: 1px solid #07006B;
  color: #07006B;
  font-weight: bold;
  font-size: 1.3rem !important;
  letter-spacing: 0px;
  width:auto;
}


.youtube {
  color: red;
  width: 45px;
}

.rs-tiktok {
  height: 45px;
  padding-top: 15px !important;
}

.skroll {
  background-color: #1E4D62 !important;
  color: white;
  margin-right: 10px;
  margin-bottom: 10px;
}


.social-sharing-icon {
  font-size: 3em !important;
  cursor: pointer;
  margin-right: 7.5px;
}


.share-icon {
  font-size: 1.8em;
  padding-right: 5px;
}

.sharer-icon{
  background-color: #07006B;
  border-radius: 7px;
  padding:6px;
  padding-right: 10px;
  color: white;
  cursor: pointer;
}
.sharer-icon *{
  color: white;
}

.clipboard-copy-btn{
  background-color:#00b5e7 !important;
  color: white;
}

.mdi-twitter{
  color:#00acee !important;
}
.mdi-reddit{
  color:#FF4500 !important;
}
.mdi-email{
  color:#D73232 !important;
}

div.v-input__append-inner{
  margin-top: 0px !important;
}

.v-icon.overlay-crown{
  color: red !important;
}




</style>