<template>
  <v-container fluid>
    <div class="d-flex justify-center align-center my-6">
      <v-icon class="compare-icon">mdi-compare-horizontal</v-icon>
      <span class="title">Compare Properties</span>
    </div>

    <v-row justify="center">
      <v-col cols="11" >
        <v-row >


          <template v-for="(property,index) in compare">

            <v-col class="col-md-4 col-6 compare-card" :key="index+property.id">
              <v-row>
                <v-card class="w-100">
                  <v-row align="center">
                    <v-col cols="12" class="">
                      <router-link :to="{name:'singleProperty',params:{slug:property.slug}}">

                      <v-img
                          :src="property.images[0]"
                          :lazy-src="property.images[0]"

                          class="compare-property-image"
                          cover
                      ></v-img>
                      </router-link>

                      <div class="compare-property-image-overlay">
                        <v-btn
                            title="Remove Property"
                            @click="removeProperty(index)"
                            color="error"
                            medium
                            class="ma-2"
                        >
                          <v-icon>mdi-delete-empty</v-icon>
                        </v-btn>
                      </div>

                    </v-col>
                  </v-row>


                  <router-link :to="{name:'singleProperty',params:{slug:property.slug}}">

                  <div class="compare-property-title col-12">
                    <span class="compare-property-code">
                      {{property.code}}
                    </span>
                    <span class="compare-property-code">
                      {{property.for}}
                    </span>

                    <span class="compare-property-price float-right">
                      NPR. {{$textifyPrice(property.price)}}
                    </span>

                    <span class="compare-property-code">
                      {{property.type}}
                    </span>

                  </div>
                  <v-card-title class="compare-property-title pt-0">
                    {{property.name }}
                  </v-card-title>
                  <v-card-subtitle class="compare-property-subtitle mt-2">
                    <v-icon>mdi-map-marker</v-icon>
                    {{property.location }}
                  </v-card-subtitle>
                  </router-link>

                  <v-card-text>
                    <div
                        class="attributes"
                    >
                      <strong> Area : </strong>
                      {{property.area}}
                    </div>
<!--                    <div-->
<!--                        class="attributes"-->
<!--                    >-->
<!--                      <strong> Price : </strong>-->
<!--                      NPR. {{$textifyPrice(property.price)}}-->
<!--                    </div>-->

<!--                    <div-->
<!--                        class="attributes"-->
<!--                    >-->
<!--                      <strong> For : </strong>-->
<!--                      {{property.for}}-->
<!--                    </div>-->
<!--                    <div-->
<!--                        class="attributes"-->
<!--                    >-->
<!--                      <strong> Type : </strong>-->
<!--                      {{property.type}}-->
<!--                    </div>-->


<!--                    <p class="text-center" v-if="property.features.length>0"> FEATURES</p>-->
                    <div
                        v-for="(feature,index) in property.features"
                        :key="'z'+index"
                        class="attributes"
                    >
                      <p class="compare-property-feature">
                        <img :src="feature.icon" :alt="feature.icon"  width="25px" />
                        <span>{{ feature.name }}: </span>

                        {{ feature.value }}
                      </p>

                    </div>

                    <p class="text-center" v-if="property.facilities.length>0">  FACILITIES</p>

                    <!-- Display property details here -->
                    <div
                        v-for="(facility,index) in property.facilities"
                        :key="'x'+index"
                        class="attributes"
                    >
                      <p class="compare-property-facility font-weight-bold">
                        <img :src="facility.icon" :alt="facility.icon"  width="25px" />

                        <span>
                        {{ facility.name }}
                        </span>
                      </p>
                    </div>


                  </v-card-text>
                </v-card>
              </v-row>
            </v-col>


          </template>


          <v-col class="col-md-4 col-6 compare-card" v-if="compare.length !== 3">
            <v-card max-width="600" class="mx-auto">
              <v-card-title>Add A Property To Compare</v-card-title>

              <v-card-text class="text--primary">
                <v-text-field
                  v-model="searchQuery"
                  label="Search"
                  outlined
                  @input="filterProperties"
                />
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col class="col-12" v-if="similar.length>0">
                    <p class="" > Similar Properties to <b>{{$store.state.compare[$store.state.compare.length-1].name}}</b> </p>

                  </v-col>



                  <v-col
                    v-for="(property, index) in properties"
                    :key="index"
                    cols="12"
                    md="4"
                    sm="6"
                    class="property-card-container"
                  >

                    <div @click="addPropertyToCompare(property)">

                      <div class="compare-thumb" :style="$inlineBgImage(property.images.length>0?property.images[0]:`https://picsum.photos/500/300?image=${ index * 5 + 10}`)"></div>

                      <p :title="property.name" class="compare-property-name">
                        {{property.name}}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue" text> Add </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Compare",
  metaInfo() {
    return {
      title: "Compare Property",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
  data() {
    return {
      searchQuery: "",
      properties:[],
      similar:false,
      similarProperties:[],
      // Add more properties to compare
    };
  },
  watch:{
    compare(){
      if(this.compare.length  === 1){
        this.getSimilarProperties(this.$store.state.compare[0].slug);
      }
      else if(this.compare.length  === 2){
        this.getSimilarProperties(this.$store.state.compare[1].slug);
      }
      if (this.compare.length === 0){
        this.properties=[]
        this.similar = false;

      }
    }
  },



  computed:{
    compare: {
      get() {
        return this.$store.state.compare;
      },
      set(value) {
        this.$store.state.compare.push(value);
      }
        // setter
      }
  },
  mounted(){
    if (this.searchQuery === ''){
      if (this.compare.length> 0){
        this.getSimilarProperties(this.$store.state.compare[0].slug);
      }
    }
  },
  methods: {
    removeProperty(index) {
      this.$store.state.compare.splice(index, 1);
    },
    addPropertyToCompare(property){
      this.$store.state.compare.push(property);
    },
    filterProperties() {
      if (this.searchQuery !== ''){
        this.$axios.get('/property-search/'+this.searchQuery).then(response=>{
          this.properties = response.data.data
          this.similar = false;

        })
      }
    },
    getSimilarProperties(slug){
      this.$store.dispatch('fetchSimilarProperty',slug).then(response=>{
        this.properties = response;
        this.similar = true;
      });
    }
  },
};
</script>

<style scoped>
/* Add custom styles here if needed */
.compare-icon {
  font-size: 40px;
  justify-content: center;
  color: #399ece;
}
.title {
  font-size: 32px !important;
  font-weight: 500;
  color: rgba(12, 59, 79, 0.77);
  justify-content: center;
  padding-left: 15px;
}
.attributes {
  font-size: 17px;
  padding: 10px !important;
}

.attributes strong{
  color: #455f6b;
}

.compare-property-title {
  color: #1E4D62;
  font-size: 20px;
  font-weight: 700;
}
.compare-property-subtitle {
  color: rgb(45, 45, 45);
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}



/*// Manish*/

.compare-property-image{
  position: relative;
  max-height: 300px;
}
.compare-property-image-overlay{
  position: absolute;
  top: 0;
  width: 100%;
}
.compare-property-image-overlay .v-btn{
  float: right;
}
.compare-property-image:hover .compare-property-image-overlay .v-btn{
  display: block;
}

.compare-card{
  width: 100%;
  padding: 20px;
  cursor: pointer;
}
.compare-card .v-card{
  border-bottom: 5px solid #1E4D62;
}
.v-card__text{
  padding-top: 0;
}

.compare-thumb{
  height:100px;
  width:100%;
  background-position: center;
  background-size: cover;
}

.facility-icon{
  color: forestgreen;
  font-weight: bold ;
}

.compare-property-feature,
.compare-property-facility{
  margin-bottom: 2px;
  text-transform: capitalize;

}
.compare-property-feature span{
  font-weight: bold;
  color: #1E4D62;
}
.compare-property-feature img,
.compare-property-facility img{
  position: relative;
  top:2.5px;
  padding-right: 5px;
  margin-top:5px
}

.compare-property-name{
  text-overflow: ellipsis; /* will make [...] at the end */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-top: 3px;
}

.compare-property-code{
  margin-bottom:0;
  background-color: #399ece;
  border-radius: 7px;
  padding: 5px 10px;
  margin-left: 5px;
  color: white;
  font-size: 0.7em;
  text-transform: capitalize;

}
.compare-property-price{
  margin-bottom:0;
  background-color: rgba(26, 92, 191, 0.69);
  border-radius: 7px;
  padding: 5px 10px;
  margin-left: 5px;
  color: white;
  font-size: 0.9em;
}

.w-100{
  width: 100% !important;
}




.v-dialog.v-dialog--active {
  /* background: #223135e8 !important; */
  background: #3f6e81 !important;
}


.v-expansion-panels > div:nth-child(1) > div:nth-child(1) > button:nth-child(1) {
  box-shadow: -8px 4px 4px 1px #3ea5924f;
}

</style>
