<template>
  <v-app>
    <v-main>
      <notifications position="bottom right" />
      <vue-progress-bar></vue-progress-bar>
      <keep-alive >
        <transition name="fade">
        <router-view />
        </transition>
      </keep-alive>
      <GoToTop/>
    </v-main>
  </v-app>
</template>

<script>
import GoToTop from "@/components/GoToTop";
import {mapState} from "vuex";
export default {
  name: 'App',
  components: { GoToTop,
  },
  computed:{
    ...mapState(['isLoading']),
  },
  data: () => ({
    //
  }),
  mounted() {
  }
};
</script>

<style>



.rendered-icon{
  position: relative;
  height:30px;
}

.s-p-feature-icon .rendered-icon{
height: 39px;
width: 31px;
}

.features .rendered-icon{
  height:35px !important;
}


.created_at{
  color: #565656;
  opacity: 0.7;
  padding-top: 1%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
