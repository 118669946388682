<template>
  <div>
<!--    <button class="go-to-top-button" @click="scrollToTop" v-show="showButton">-->
<!--      <v-icon>mdi-help-circle-outline</v-icon>-->
<!--    </button>-->

    <v-dialog
        v-model="dialog"
        width="600px"
        class="faq-window"
    >
      <template v-slot:activator="{ on, attrs }">

        <button class="faq-button"
                v-bind="attrs"
                v-on="on"
                v-show="showButton">
          <v-icon class="help-faq-btn">mdi-help-circle-outline</v-icon>
        </button>
      </template>

            <div class="text-center my-2" style="position: relative">
              <div id="faq" ref="faq" style="position:absolute; top: -600px;left:0;height: 1px; background: red;width: 1px"/>

              <v-btn
                color="#00b8e6"
                elevation="2"
                large
                class="my-10 white--text"
                tile
                x-large
                >Frequently Asked Questions</v-btn
              >
              <!-- <p class="text-h6 text-sm-h5 text-md-h5 font-weight-bold">
                WE ARE HERE TO HELP YOU WITH ANY <br />
                QUESTION YOU MAY HAVE
              </p> -->
            </div>



      <v-responsive class="mt-5 mb-16 mx-auto" max-width="80%">

      <v-expansion-panels>
        <v-expansion-panel
            v-for="(faq, i) in paginatedFaqs"
            :key="i"
        >
          <v-expansion-panel-header>
            {{ faq.question }}
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            {{ faq.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>





        <v-col class="col-12" v-if="faqs.length > perPage">
          <v-pagination
              v-model="currentPage"
              :length="pageCount"
              @input="handlePageChange"
          ></v-pagination>
        </v-col>
      </v-responsive>


    </v-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      showButton: false,
      dialog: false,

      currentPage: 1,
      pageCount: 0,
      perPage: 5


    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  created() {
    this.pageCount = Math.ceil(this.faqs.length / this.perPage)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed:{
    ...mapState(['faqs']),
    paginatedFaqs() {
      return this.faqs.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      )
    },
  },
  methods: {
    handlePageChange(page) {
      this.currentPage = page
    },
    toggleExpansion(item) {
      this.$set(item, "isExpanded", !item.isExpanded);
    },
    getIcon(isExpanded) {
      return isExpanded ? "mdi-minus" : "mdi-plus";
    },
    handleScroll() {
      // Show the button when user scrolls beyond a certain threshold (e.g., 300px)
      this.showButton = window.pageYOffset > 200;
    },

  }
};
</script>

<style>


.v-dialog.v-dialog--active {
  background: #e5f0f2 !important;
}




.theme--light.v-expansion-panels .v-expansion-panel {
  /*background: linear-gradient(#68e3ff,#d2e9f0e3);*/
  background: #68e3ff;
  margin-top: 16px;
}



.faq-button {
  position: fixed;
  bottom: 15px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #3CC9EC;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  border:1px solid rgba(255, 255, 255, 0.4);
  z-index: 9;
}

.faq-button {
  animation: wiggle 1s ease-in-out infinite;

}


.help-faq-btn{
  color: white !important;
  font-size: 35px !important;
}

.faq-button:hover {
  opacity: 1;
}

/* Define the keyframes for the wiggle animation */
@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


</style>