<template>
  <div v-if="teams.length > 0">
    <v-container fluid>
      <v-row justify="center">
        <v-col class="col-md-10 col-12">
          <p class="team-title"> Our Team</p>
        </v-col>
        <v-col class="col-md-10 col-12">
          <v-row>
            <v-col class="col-lg-3 col-sm-4 col-12 mb-5" v-for="(team,n) in teams" :key="n" >

              <div class="pa-md-5 pa-sm-5">
                <v-row justify="center" align-content="center">
                  <div class="team-image-container" :style="$inlineBgImage(team.image)"></div>
                  <v-col class="col-12 pb-0 mb-0">
                    <p class="team-person-name "> {{ team.name }} </p>
                    <p class="team-person-post "> {{ team.position }}</p>
                  </v-col>
                  <v-col class="col-12 text-center pt-0">
                    <a :href="team.fb" v-if="team.fb">
                      <v-icon class="team-fb-icon">mdi-facebook</v-icon>
                    </a>
                    <a :href="team.linkedin" v-if="team.linkedin">
                    <v-icon class="team-li-icon">mdi-linkedin</v-icon>
                    </a>
                  </v-col>
                </v-row>
              </div>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Teams",
  computed:{
    ...mapState(['teams'])
  }
}
</script>

<style scoped>


.team-title{
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  font-size: 38px;
  font-weight: bold;
  font-family: 'Roboto',sans-serif;
  color: rgb(30, 77, 98);
}

.team-image-container{
  height: 15rem !important;
  width: 15rem !important;
  border-radius: 50%;
  background-size: cover;
  background-position: top;
  border:1px solid rgba(98, 160, 194, 0.56);
}

.team-title,.team-person-name,.team-person-post{
  text-align: center !important;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.team-person-name{
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.team-person-post{
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.team-fb-icon,.team-li-icon{
  font-size: 1.8em;
  margin-left: 5px;
}


</style>