<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col class="col-md-10 col-11">
        <v-row  class="search-container">
          <v-col class="col-md-11 col-9">
            <v-text-field
              class="p-search"
              label="Search"
              placeholder="Search through name, address , property sizes and budget"
              hide-details="auto"
              solo
              @keydown.enter="$emit('search', searchWords)"
              v-model="searchWords"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn
              class="p-search-btn"
              height="100%"
              @click="$emit('search', searchWords)"
            >
              Search</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SearchComponent",
  props:['words'],
  data(){
    return{
    searchWords:'',
    }
  },
  watch:{
    words(){
      this.searchWords = this.words;
    }
  }
};
</script>

<style scoped>
.search-container {
  border-radius: 5px;
  /*background-color: rgba(30, 77, 98, 0.68) !important;*/
}

.search-container v-col{
  padding:0px !important;
  margin:0px !important;
}
</style>
