<template>
  <div class="div">
    <HeroHeader :titles="titles"/>
    <v-container class="col-lg-9 pt-4 pb-4">
      <div
          role="form"
          class="property-req-form"
          dir="ltr"
      >
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">

          <form
              ref="requestForm"
              method="post"
              @submit.prevent="handleSubmit(initiateRequest)"
          >
            <div class="row">
              <div class="col-md-4 form-group">
                <ValidationProvider rules="name" v-slot="{ errors }">
                  <v-text-field name="name" v-model="requestForm.name" placeholder="Name" :error-messages="errors[0]">
                    <template #label>
                      Name <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </div>
              <div class="col-md-4 form-group">
                <ValidationProvider rules="phone" v-slot="{ errors }">
                  <v-text-field name="phone-number" v-model="requestForm.phone" placeholder="Phone"
                                :error-messages="errors[0]">
                    <template #label>
                      Phone Number <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </div>
              <div class="col-md-4 form-group">
                <ValidationProvider rules="email" v-slot="{ errors }">
                  <v-text-field name="email" v-model="requestForm.email" placeholder="Email Address"
                                :error-messages="errors[0]">
                    <template #label>
                      Email Address
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </div>
              <div class="col-md-4 form-group">
                <v-select
                    :items="requestType"
                    v-model="requestForm.request_type"
                    color="pink"
                    label="Request Type"
                    required
                >
                  <template #label>
                    Request Type
                  </template>
                </v-select>
              </div>
              <div class="col-md-4 form-group">
                <v-select
                    :items="propertyType"
                    v-model="requestForm.property_type"

                    color="pink"
                    label="Property Type"
                    required
                >
                  <template #label>
                    Property Type
                  </template>
                </v-select>
              </div>
              <div class="col-md-4 form-group">
                <v-select
                    :items="urgentType"
                    v-model="requestForm.urgent_type"
                    color="pink"
                    label="Urgent Type"
                    required>
                  <template #label>
                    How Urgent?
                  </template>
                </v-select>
              </div>
              <div class="col-md-4 form-group">
                <v-text-field
                    name="which-place"
                    v-model="requestForm.location"
                >
                  <template #label>
                    Property Location
                  </template>
                </v-text-field>
              </div>
              <div class="col-md-4 form-group">
                <ValidationProvider rules="city" v-slot="{ errors }">
                  <v-select
                      v-model="requestForm.city"
                      :items="cities"
                      :error-messages="errors[0]"
                  >
                    <template #label>
                      Property City
                       <span class="red--text">*</span>

                    </template>
                  </v-select>
                </ValidationProvider>
              </div>
              <div class="col-md-4 form-group">
                <v-text-field
                    type="number"
                    name="price-range"
                    v-model="requestForm.price">
                  <template #label>
                    Price
                  </template>
                </v-text-field>
              </div>
              <div class="col-md-3 form-group">
                <v-text-field
                    type="number"
                    v-model="requestForm.car_parking_space"
                    name="car_parking_space">
                  <template #label>
                    Car Parking Space
                  </template>
                </v-text-field>
              </div>
              <div class="col-md-3 form-group">
                <v-text-field
                    type="number"

                    v-model="requestForm.no_of_bedrooms"
                    name="bedrooms"
                >
                  <template #label>
                    Number of Bedrooms
                  </template>
                </v-text-field>
              </div>
              <div class="col-md-6 form-group">
                <v-text-field
                    type="text"

                    v-model="requestForm.property_size"
                    name="size-of-property"
                >
                  <template #label>
                    Size of Property
                  </template>
                </v-text-field>
              </div>
              <div class="col-12 form-group">
                <v-textarea placeholder="" v-model="requestForm.description">
                  <template #label>
                    Description of Request
                  </template>
                </v-textarea>
              </div>
              <div class="col-12 form-group">
                <v-btn class="blue--btn" type="submit">Request Property</v-btn>
              </div>
            </div>
            <v-row>
              <v-col class="col-md-12 col-12" v-if="requestSuccess">
                <v-alert
                    type="success" variant="tonal">
                  Thank you for requesting property with us! Your request has been received and is being processed. We
                  will review your submission and get back to you as soon as possible with further details. You should
                  receive a confirmation email shortly. If you have any questions or concerns, please don't hesitate to
                  contact us. We appreciate your business and look forward to serving you!
                </v-alert>
              </v-col>
            </v-row>

          </form>
        </ValidationObserver>
      </div>
    </v-container>
  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader";
import {ValidationObserver, ValidationProvider, extend, configure} from 'vee-validate';
import {required, email, numeric} from 'vee-validate/dist/rules'


extend('name', {
  ...required,
  message: 'Name is required'
});

extend('phone', {
  ...required,
  ...numeric,
  message: 'Contact number must be numeric'
});


extend('email', {
  ...email,
  message: 'E-mail must be valid'
});

extend('price', {
  ...numeric,
  message: 'Price must be a number',
  params: ['^\\d+$']
});


extend('car_parking_space', {
  ...numeric,
  message: 'Car parking space must be a number',
  params: ['^[0-9]+$']
});

extend('city', {
  ...required,
  message: 'City must be selected',
});

extend('no_of_bedrooms', {
  ...numeric,
  message: 'Number of Bedrooms must be a number',
  params: ['^[0-9]+$']
});


configure({
  validateOnInput: true,
  validateOnChange: true,
  validateOnBlur: true,
  generateMessage: context => {
    const messages = {
      required: '{_field_} is required',
      ...context.messages
    };
    return messages[context.rule.name](context);
  }
});

export default {
  name: "PropertyRequest",
  components: {HeroHeader, ValidationObserver, ValidationProvider},
  metaInfo() {
    return {
      title: "Property Request",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
  data() {
    return {
      titles: {
        mainTitle: "Request A Property Form",
        subTitle: "You'll be contacted by us.",
      },
      cities: [
        'Kathmandu', 'Bhaktapur', 'Lalitpur'
      ],
      requestForm: {
        name: '',
        phone: '',
        email: '',
        request_type: '',
        property_type: '',
        price: '',
        urgent_type: '',
        location: '',
        no_of_bedrooms: '',
        property_size: '',
        car_parking_space: '',
        description: '',
      },
      requestSuccess: false,
      requestType: ["For Sale", "For Rent"],
      propertyType: ["House", "Land", "Apartment"],
      urgentType: ["Very Urgent", "Urgent", "Within a Week", "Within A Month"],
    };

  },
  methods: {
    initiateRequest() {
      this.requestSuccess = false;
      this.$refs.observer.validate().then(result => {
        if (result) {
          this.$axios.post('request-property', this.requestForm)
              .then(() => {
                this.requestSuccess = true;
                this.requestForm = {
                  name: '',
                  phone: '',
                  email: '',
                  requestType: '',
                  propertyType: '',
                  price: '',
                  urgentType: '',
                  location: '',
                  no_of_bedrooms: '',
                  property_size: '',
                  car_parking_space: '',
                  description: '',
                };
                this.$refs.observer.reset();
              })

        }
      });
    }
  }
};
</script>

<style>

.property-req-form {
  border: 3px solid rgba(26, 92, 191, 0.1);
  border-radius: 15px;
  padding: 50px 20px;
}
</style>
