<template>
  <div>
    <HeroHeader :titles="titles"/>
    <div ref="targetDiv" style="position: absolute; top: 7%"></div>
    <SearchComponent :words="search.words" @search="searchProperties"/>

    <v-container fluid id="scrollContainer">
      <v-row justify="center">
        <v-col class="col-md-12 col-12">
          <v-row>
            <!--FilterSection-->
            <v-col class="col-md-3 col-sm-3 col-12 p-filter-section pl-5">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel>
                  <v-row justify="center">
                    <v-col class="col-12 p-filter-header">
                      <v-expansion-panel-header>
                        <h3 class="filter-title">Filters</h3>

                        <template v-slot:actions>
                          <v-icon>
                            {{ getIcon(panel.length) }}
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>


<!--                      <v-row justify="center" class="py-2">-->
<!--                        <v-col cols="auto">-->
<!--                          <v-btn class="blue&#45;&#45;btn ma-2" @click="resetFilter">-->
<!--                            Reset Filters-->
<!--                          </v-btn-->
<!--                          >-->
<!--&lt;!&ndash;                          <v-btn class="green&#45;&#45;btn ma-2" @click="searchProperties(search.words)">&ndash;&gt;-->
<!--&lt;!&ndash;                            Search Filters&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-btn>&ndash;&gt;-->
<!--                        </v-col>-->
<!--                      </v-row>-->

                    </v-col>
                  </v-row>
                  <v-expansion-panel-content>


                 <!--Property For-->

                    <v-row >
                      <v-col class="col-12">
                        <h3 class="filter-title pb-3">Property Status</h3>


                        <v-btn v-for="name in propertyFor" class="mr-2 mb-2" @click="selectedPropertyFor=name" :class="selectedPropertyFor === name?'blue--btn':''" :key="name">
                          {{name}}
                        </v-btn>

                      </v-col>
                    </v-row>

                    <!--Property Types-->
                    <v-row >
                      <v-col class="col-12">
                        <h3 class="filter-title pb-3">Property Type</h3>

                        <v-btn v-for="name in propertyNames" class="mr-2 mb-2" @click="selectedPropertyType=name" :class="selectedPropertyType === name?'blue--btn':''" :key="name">
                          {{name}}
                        </v-btn>

                      </v-col>
                    </v-row>
                    <!--                    //PRICE RANGES-->
                    <v-row>
                      <v-col class="col-12">
                        <h3 class="filter-title">Price Ranges</h3>


                        <v-row>
                          <v-col class="col-12">

                            <v-radio-group
                                v-model="selectedPriceRange"
                            >
                              <v-radio v-for="(value,key,n) in priceRanges"
                                       :key="n"
                                       :label="key"
                                       :value="value"
                                       @change="search.searchValue=value"

                              >

                              </v-radio>

                            </v-radio-group>

                          </v-col>
                        </v-row>

                        <v-row v-show="false">
                          <v-col class="col-6">
                            <v-text-field
                                v-model="search.searchValue[0]"
                                class="col-6 float-left"
                                solo
                                :hide-details="true"
                            />
                          </v-col>
                          <v-col class="col-6">
                            <v-text-field
                                v-model="search.searchValue[1]"
                                class="col-6 float-left"
                                solo
                                :hide-details="true"
                            />
                          </v-col>

                        </v-row>
                      </v-col>
                    </v-row>
                    <!--                    //PRICE RANGES-->

                    <v-row>
                      <v-col class="col-12">
                        <h3 class="filter-title">Package Type</h3>

                        <!--                        <v-checkbox-->
                        <!--                            label="Budget Package"-->
                        <!--                            color="#1E4D62"-->
                        <!--                            value="budget"-->
                        <!--                            v-model="search.package"-->
                        <!--                            hide-details-->
                        <!--                        ></v-checkbox>-->
                        <v-checkbox
                            label="Premium Package"
                            color="#1E4D62"
                            value="premium"
                            v-model="search.package"
                            hide-details
                        ></v-checkbox>
                        <!--                        <v-checkbox-->
                        <!--                            label="Value Package"-->
                        <!--                            color="#1E4D62"-->
                        <!--                            value="value"-->
                        <!--                            v-model="search.package"-->
                        <!--                            hide-details-->
                        <!--                        ></v-checkbox>-->
                      </v-col>
                    </v-row>

                    <v-row v-show="false" v-if="rentOrSale === 'rent'">
                      <v-col class="col-12">
                        <h3 class="filter-title">Rent Period</h3>

                        <v-radio-group v-model="search.checkbox">
                          <v-radio
                              label="1 month"
                              value="option1"
                              color="#1E4D62"
                          ></v-radio>
                          <v-radio
                              label="3 months"
                              value="option2"
                              color="#1E4D62"
                          ></v-radio>
                          <v-radio
                              label="6 months/1 year"
                              value="option3"
                              color="#1E4D62"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <!--          // Searched Cards-->
            <v-col class="col-md-9 col-sm-9 col-12 pr-md-16">
              <v-row>
                <v-col class="col-12">
                  <v-row>

<!--                    -->
<!--                    <v-col class="col-md-3 col-6 p-select-filters text-capitalize">-->
<!--                      <v-select-->
<!--                          :items="propertyNames"-->
<!--                          label="Property Name"-->
<!--                          v-model="selectedPropertyType"-->
<!--                          solo-->
<!--                          @change="changePropertyType"-->
<!--                          :hide-details="true"-->
<!--                      ></v-select>-->
<!--                    </v-col>-->
<!--                    <v-col class="col-md-3 col-6 p-select-filters">-->
<!--                      <v-select-->
<!--                          :items="propertyFor"-->
<!--                          v-model="selectedPropertyFor"-->
<!--                          label="Looking For"-->
<!--                          solo-->
<!--                          :hide-details="true"-->
<!--                          @change="changePropertyType"-->
<!--                      ></v-select>-->
<!--                    </v-col>-->
<!--                    -->

<!--                    <v-col class="col-md-3 col-12 p-select-filters">-->
                    <v-col class="col-md-9 col-12 p-select-filters">

                      <v-autocomplete
                          class="auto-location"
                          v-model="selectedLocation"
                          :items="searchedLocation"
                          filled
                          dense
                          hide-no-data
                          hide-details
                          solo-inverted
                          flat
                          hide-selected
                          placeholder="Search Property By Location"
                          :search-input.sync="locationWords"
                          @update:search-input="searchLocations"
                      ></v-autocomplete>

                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col class="col-12 pa-3">
                  <v-row v-if="dynamic_shop.length > 0">
                    <v-col
                        class="col-md-4 col-sm-6 col-12 filter-card-container"
                        v-for="(item,n) in dynamic_shop"
                        :key="n"
                        @click="$router.push({ name: 'singleProperty',params:{slug:item.slug} })"

                    >
                      <v-row>
                        <PropertyCard :item="item"/>
                      </v-row>

                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <template v-if="search.searched">
                      <div class="col-12">
                        <div class="no-image-container">
                          <v-img src="@/assets/img/not-found.jpg" cover/>
                        </div>
                      </div>
                      <p class="text-h5 pl-md-8"> Such property doesn't exist, Please try different keywords</p>
                    </template>

                    <template v-else>
                      <div style="text-align: center;width: 100%"
                           v-if="isLoading"
                      >
                        <v-progress-circular
                            indeterminate
                            :size="70"
                            :width="7"
                            color="#00bbe4"
                        ></v-progress-circular>
                      </div>

                      <div class="col-12 empty-data-container" v-else>

                        <div class="d-flex justify-center">

                          <div class="no-image-container">
                            <v-img src="@/assets/img/not-found.jpg" cover/>
                          </div>

                        </div>

                        <p class="text-h5 text-center no-properties-text">
                          No Properties Found
                        </p>

                      </div>

                    </template>
                  </v-row>


                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-12" v-if="pagination.total > 1 ">

                  <v-pagination
                      v-model="pagination.current"
                      :length="pagination.total"
                      :total-visible="pagination.totalVisible"
                      @input="paginateNext"
                  ></v-pagination>

                </v-col>
              </v-row>

            </v-col>


          </v-row>
        </v-col>
      </v-row>


    </v-container>

  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader";
import SearchComponent from "@/components/SearchComponent";
import * as VExpansionPanel from "vuetify/es5/components/VExpansionPanel";
import {mapState} from "vuex";
import store from "@/store";
import Vue from "vue";
import PropertyCard from "@/components/PropertyCard";

export default {
  name: "DynamicShop",
  metaInfo() {
    return {
      title: this.capitalizeStr(this.currentPage),
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
  components: {
    HeroHeader,
    SearchComponent,
    VExpansionPanel,
    PropertyCard
  },
  data() {
    return {
      selectedPropertyType: '',
      selectedPropertyFor: '',
      selectedLocation: '',
      locationWords: '',
      searchedLocation: [],
      panel: [0],
      sliderMax: 500000000,
      pagination: {
        current: 1,
        total: 0,
        totalVisible: 7,
      },
      search: {
        searched: false,
        searchValue: [0, 500000000],
        package: "",
        checkbox: "option1",
        words: '',
      },
      titles: {
        mainTitle: "",
        subTitle: "",
      },
      propertyNames: ["All", "House", "Apartment", "Land"],
      propertyFor: ["All", "Sale", "Rent"],
      propertyLocations: [
        "PropertyLocation1",
        "PropertyLocation2",
        "PropertyLocation3",
      ],
      priceRanges: {
        'All Range': [0, 500000000],
        'Less than 1 crore': [0,10000000],
        '1 crore - 2 crore': [10000000,20000000],
        '2 crore - 3 crore': [20000000,30000000],
        '3 crore - 4 crore': [30000000,40000000],
        '4 crore - 6 crore': [40000000,60000000],
        '6 crore - 10 crore': [60000000,100000000],
        'More than 10 crore': [100000000,500000000],
      },
      selectedPriceRange:[0,500000000],
    };
  },
  watch: {
    selectedPriceRange(){
        this.changePropertyType();
    },
    selectedPropertyFor(){
        this.changePropertyType();
    },
    selectedPropertyType(){
        this.changePropertyType();
    },
    "search.package"(){
        this.changePropertyType();
    },
    "search.searchValue"(){
        this.changePropertyType();
    },
    "$route.params.pathMatch"(newVal) {
      const capitalizedStr = newVal
          .split("-")
          .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(" ");

      this.metaInfo = {
        title: capitalizedStr,
      };
      this.titles = {
        mainTitle: capitalizedStr,
        subTitle: "Find Your Property With Us",
      };
      this.getProperties();
      this.gotoViewer();
    },

    "$route.query"() {
      if (this.$route.query) {
        if (this.$route.query.propertyFor && this.$route.query.propertyFor !== "") {
          this.selectedPropertyFor = this.$route.query.propertyFor;
        }
        if (this.$route.query.propertyType && this.$route.query.propertyType !== "") {
          this.selectedPropertyType = this.$route.query.propertyType;
        }
        if (this.$route.query.minPrice && this.$route.query.minPrice !== "") {
          this.search.searchValue[0] = this.$route.query.minPrice;
        }
        if (this.$route.query.maxPrice && this.$route.query.maxPrice !== "") {
          this.search.searchValue[1] = this.$route.query.maxPrice;
        }
        if (this.$route.query.words && this.$route.query.words !== "") {
          this.search.words = this.$route.query.words;
        }
        if (this.$route.query.premium) {
          this.search.package = 'premium';
        }

        this.getProperties();
      }
    },
    selectedLocation() {
      if (this.selectedLocation !== '') {
        this.searchProperties();
      }
    },
    shouldCollapse() {
      if (this.shouldCollapse) {
        this.panel = [];
      } else {
        this.panel = [0];
      }
    },
  },
  computed: {
    ...mapState(['dynamic_shop', 'isLoading']),
    currentPage() {
      return this.$route.params.pathMatch;
    },
    rentOrSale() {
      return this.splitString(this.$route.params.pathMatch)[1];
    },
    whatProperty() {
      return this.splitString(this.$route.params.pathMatch)[0];
    },
    shouldCollapse() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  beforeRouteEnter(to, from, next) {
    const goTo = to.params.pathMatch;
    const possiblePaths = [
      "apartment-rent",
      "apartment-sale",
      "house-sale",
      "house-rent",
      "land-sale",
      "land-lease",
    ];
    if (goTo === 'search' || possiblePaths.includes(goTo)) {
      next((vm) => {

        if (goTo !== 'search') {


          vm.getProperties();

          const title = vm.capitalizeStr(goTo);

          //Fetch Data
          //Change Data

          // Update Titles of page before route enters
          vm.titles = {
            mainTitle: title=='Land Rent'?'Land Lease':title,
            subTitle: "Find Your Property With Us",
          };

          // Update Meta
          vm.metaInfo = {
            title: title,
          };

        } else {
          vm.titles = {
            mainTitle: 'Search',
            subTitle: "Find Your Property With Us",
          };
          vm.metaInfo = {
            title: 'Search Properties ',
          };


          vm.searchProperties(to.query.words);

        }

        Vue.prototype.$Progress.finish()

      });
    } else {
      next({name: 'error'});
    }
  },
  beforeRouteUpdate(to, from, next) {
    const goTo = to.params.pathMatch;
    const possiblePaths = [
      "apartment-rent",
      "apartment-sale",
      "house-sale",
      "house-rent",
      "land-sale",
      "land-lease",
    ];

    if (goTo === 'search' || possiblePaths.includes(goTo)) {
      next((vm) => {
        if (goTo !== 'search') {
          vm.getProperties();
          vm.pagination = {
            current: 1,
            total: 0,
            totalVisible: 7,
          };
        }
        else{
          vm.searchProperties();
        }

      });
    } else {
      next({name: 'error'});
    }
    Vue.prototype.$Progress.finish()

  },

  methods: {
    gotoViewer() {
      this.$nextTick(() => {
        const targetDiv = this.$refs.targetDiv;
        targetDiv.scrollIntoView({behavior: "smooth", block: "start"});
      });
    },
    changePropertyType() {

      const baseUrl = this.$route.params.pathMatch;
      if (baseUrl !== 'search' && this.selectedPropertyType.toLowerCase() !== 'all' && this.selectedPropertyFor.toLowerCase() !== 'all') {
        let dynamicUrl;
        dynamicUrl = baseUrl.replace(baseUrl, this.selectedPropertyType.toLowerCase() + "-" + this.selectedPropertyFor.toLowerCase());
        if(baseUrl !== 'search' && this.selectedPropertyType.toLowerCase() === 'land' && this.selectedPropertyFor.toLowerCase() === 'rent') {
             dynamicUrl = baseUrl.replace(baseUrl, this.selectedPropertyType.toLowerCase() + "-lease" );
          }
        if (baseUrl !== dynamicUrl){
          this.$router.replace(dynamicUrl);
        }
        this.selectedLocation = '';
        this.searchProperties();

      } else {
        this.searchProperties();
        if (baseUrl !== 'search') {
          this.$router.replace('/search');
        }
      }
    },
    getProperties() {
      const routeMappings = {
        'land-sale': ['land', 'sale'],
        'apartment-sale': ['apartment', 'sale'],
        'house-sale': ['house', 'sale'],
        'land-lease': ['land', 'sale'],
        'apartment-rent': ['apartment', 'rent'],
        'house-rent': ['house', 'rent'],
      };
      const route = this.$route.params.pathMatch;
      const dataToFetch = routeMappings[route];
      if (dataToFetch) {
        dataToFetch.push(this.pagination.current);
        store.dispatch('fetchData', dataToFetch).then(response => {
          this.pagination.current = response.current_page;
          this.pagination.total = response.last_page;
          window.scrollTo({
            top: document.getElementById('scrollContainer').offsetTop,
            behavior: 'smooth'
          });
        });
      } else {
        this.searchProperties();
      }

    },

    getIcon(isExpanded) {
      return isExpanded ? "mdi-chevron-up" : "mdi-chevron-down";
    },
    capitalizeStr(newVal) {
      return newVal
          .split("-")
          .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(" ");
    },
    paginateNext(value) {
      this.pagination.current = value;
      if (this.selectedPropertyType !== 'All' && this.selectedPropertyFor !== 'All') {
        this.searchProperties();
      } else {
        this.getProperties();
      }
    },
    splitString(str) {
      const parts = str.split("-");
      return [parts[0], parts[1]];
    },
    resetFilter() {
      this.search = [];
      this.search.searchValue = [0, this.sliderMax];
      this.selectedPriceRange=[0,this.sliderMax];

      this.search.package = '';
      this.locationWords = '';
      this.selectedLocation = '';
      this.$router.replace('/search');
      this.selectedPropertyType = 'All';
      this.selectedPropertyFor = 'All';
    },
    searchProperties(searchWords = '') {
      let data = {};
      data.words = searchWords;
      if (this.search.words) {
        data.words = this.search.words;
      }
      if (searchWords){
        this.search.words = searchWords;
      }

      if (this.selectedLocation) {
        data.location = this.selectedLocation;
      }

      if (this.$route.query.propertyFor){
        data.propertyFor = this.$route.query.propertyFor.toLowerCase();
      }


      if (this.selectedPropertyType && this.selectedPropertyType.toLowerCase() !== 'all') {
        data.propertyType = this.selectedPropertyType.toLowerCase();
      }
      if (this.selectedPropertyFor.toLowerCase() === 'all') {
        delete data.propertyFor;
      }
      else{
        data.propertyFor = this.selectedPropertyFor.toLowerCase();
      }


      data.minPrice = this.search.searchValue[0];
      data.maxPrice = this.search.searchValue[1];


      if (this.pagination.current) {
        data.page = this.pagination.current;
      } else {
        data.page = 1;
      }

      if (this.search.package === 'premium') {
        data.premium = true;
      }


      this.$store.dispatch('searchProperty', data).then(response => {
        this.pagination.current = response.current_page;
        this.pagination.total = response.last_page;
      });

      //After Search if small screen collapse
      if (this.shouldCollapse) {
        this.panel = [];
      }
      //
      // this.pagination.current = 1;
      // this.pagination.total = 1;
    },

    letsCompare(item) {
      this.$store.dispatch('compareProperties', item).then(() => {
        this.$router.push({name: "compareProperty"});
      })
    },
    searchLocations() {
      this.$axios.post('/locations', {searchWords: this.locationWords}).then(response => {
        this.searchedLocation = response.data;
      })
    }

  },

  mounted() {
    const baseUrl = this.$route.params.pathMatch
    if (baseUrl !== 'search') {
      const arr = baseUrl.split("-");
      this.selectedPropertyType = arr[0].charAt(0).toUpperCase() + arr[0].slice(1);
      this.selectedPropertyFor = arr[1].charAt(0).toUpperCase() + arr[1].slice(1);
      if (this.selectedPropertyType == 'Land' && this.selectedPropertyFor == 'Lease'){
        this.selectedPropertyFor = 'Rent';
      }
      // this.getProperties();
    } else {
      this.selectedPropertyType = "All";
      this.selectedPropertyFor = "All";


      if (this.$route.query) {
        if (this.$route.query.propertyFor && this.$route.query.propertyFor !== "") {
          this.selectedPropertyFor = this.$route.query.propertyFor;
        }
        if (this.$route.query.propertyType && this.$route.query.propertyType !== "") {
          this.selectedPropertyType = this.$route.query.propertyType;
        }

        if (this.$route.query.minPrice && this.$route.query.minPrice !== "") {
          this.search.searchValue[0] = this.$route.query.minPrice;
        }
        if (this.$route.query.maxPrice && this.$route.query.maxPrice !== "") {
          this.search.searchValue[1] = this.$route.query.maxPrice;
        }

        if (this.$route.query.minPrice) {
          const minPrice = parseInt(this.search.searchValue[0]);
          const maxPrice = parseInt(this.search.searchValue[1]);
          for (const key in this.priceRanges) {
            const [min, max] = this.priceRanges[key];
            if (minPrice === min && (!this.$route.query.maxPrice || maxPrice === max)) {
              this.selectedPriceRange = this.priceRanges[key];
              break;
            }
          }
        }

        if (this.$route.query.words && this.$route.query.words !== "") {
          this.search.words = this.$route.query.words;
        }
        if (this.$route.query.premium) {
          this.search.package = 'premium';
        }

        if (this.selectedPropertyType == 'Land' && this.selectedPropertyFor == 'Lease'){
          this.selectedPropertyFor = 'Rent';
        }

        this.getProperties();

      }


    }


    // let dynamicUrl = baseUrl.replace(baseUrl, this.selectedPropertyType.toLowerCase() + "-" + arr[1]);
  },
};
</script>

<style>
.p-search .v-input__slot {
  background: #1e4d62 !important;
  padding-left: 10px;
  border-radius: 13px;
}

.p-search ::placeholder {
  color: white !important;
}

.p-search * {
  color: white !important;
}

.p-search-btn {
  height: 100%;
  background-color: #00bbe4 !important;
  color: white !important;
}

.p-filter-header {
}

.filter-title {
  color: #184a62;
}

.p-filter-section {
  background-color: #f9f4f4;
  height: auto;
}

/*//search*/

.filter-card-container {
  padding: 30px;
  position: relative;
}


.premium-crown-dynamic {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0;
  margin-right: 0;
  font-size: 25px !important;
}

.premium-crown-dynamic * {
  color: red !important;
  font-size: 40px !important;
  transform: rotate(35deg);
}

.filter-cards:hover .property-views,
.filter-cards:hover .property-compare-btn {
  display: inline-block;
}

.p-select-filters .v-input__slot,
.p-select-filters .v-select__slot * {
  background-color: #184a62 !important;
  color: white !important;
}

.auto-location  .v-input__slot {
  height: 48px !important;
}

.p-select-filters .v-label,
.p-select-filters .v-select__selection,
.p-select-filters .v-icon.mdi-menu-down {
  color: white !important;
}

.theme--light.v-input input::placeholder, .theme--light.v-input textarea::placeholder {
  color: white !important;
}

.no-image-container {
  max-height: 350px;
  max-width: 350px;
}


/*Range Slider */
div.v-slider__thumb-container:nth-child(5) > div:nth-child(2) > div:nth-child(1),
div.v-slider__thumb-container:nth-child(4) > div:nth-child(2) > div:nth-child(1) {
  height: 57px !important;
  width: 57px !important;
  background-color: #1E4D62 !important;
}

.range-slider-container {
  margin-top: 0;
}


.pro-title {
  /*min-height: 34px;*/
  max-height: 30px;
  overflow: hidden;
}

.empty-data-container {
  text-align: center;
}
.no-properties-text{
  font-weight: bold !important;
  color: #436f7b;
}

</style>
