<template>
  <button class="go-to-top-button" @click="scrollToTop" v-show="showButton">
    <v-icon>mdi-chevron-up</v-icon>
  </button>
</template>

<script>
export default {
  data() {
    return {
      showButton: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Show the button when user scrolls beyond a certain threshold (e.g., 300px)
      this.showButton = window.pageYOffset > 500;
    },
    scrollToTop() {
      // Smoothly scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style scoped>
.go-to-top-button {
  position: fixed;
  bottom: 90px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #3CC9EC;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  border:1px solid rgba(255, 255, 255, 0.4);
  z-index: 9;
}

.v-icon{
  color: white !important;
  font-size: 35px !important;
}

.go-to-top-button:hover {
  opacity: 1;
}
</style>