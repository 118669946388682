<template>
  <div>
    <v-container fluid class="p-footer">
      <v-row justify="center">
        <v-col class="col-md-11 col-12">
          <v-row>
            <v-col class="col-md-3 col-12">
              <v-img v-if="contact_details.logo.length>0" :src="contact_details.logo"  contain height="150px"></v-img>





            </v-col>

            <v-col class="col-md-2 col-12">
              <h3>Quick Links</h3>
              <v-list color="transparent">
                <v-list-item
                    class="px-3"
                    v-for="(item, index) in quickLinks"
                    :key="index"
                    :title="item.title"
                    :to="item.link && item.link.includes('?') ? '/search'+ item.link : { name: item.link } "
                >{{ item.title }}</v-list-item
                >
              </v-list>
            </v-col>



            <v-col class="col-md-2 col-12">
              <h3>Contact</h3>
              <v-list color="transparent">
                <v-list-item
                    class="px-3"
                    v-for="(item, index) in contactLinks"
                    :key="index"
                    :title="item.title"
                    :to="item.link && item.link.includes('#') ?  '/'+item.link : { name: item.link } "
                >{{ item.title }}</v-list-item
                >


              </v-list>
            </v-col>


            <v-col class="col-md-2 col-12">
              <h3>Resources</h3>
              <v-list color="transparent">
                <v-list-item
                    class="px-3"
                  v-for="(item, index) in resourcesLinks"
                  :key="index"
                  :title="item.title"
                  :to="item.link ? { name: item.link } : '/#'"
                >{{ item.title }}</v-list-item
                >
              </v-list>
            </v-col>



            <v-col class="col-md-3 col-12">
              <h3>Follow Us</h3>

              <v-row >
                <div class="footer-icon-container px-3">
                  <template v-for="(soc,i) in socials">
                    <template v-if="soc.link">
                      <a :href="soc.link" target="_blank" :key="i">
                        <div class="footer-icons"  :key="i" >
                          <v-icon class="f-s-icons s-icons" :class="soc.icon.replace('mdi-','')" v-if="soc.icon !== 'mdi-tiktok' && soc.icon !== 'mdi-instagram' && soc.icon !== 'mdi-whatsapp'  && soc.icon !== 'mdi-linkedin' "  >{{ soc.icon }}</v-icon>
                          <v-img src="@/assets/img/whatsapp.png" v-if="soc.icon === 'mdi-whatsapp'"  alt="" class="socc-icon whatsapp s-icons" contain />
                          <v-img src="@/assets/img/linkedin.png" v-if="soc.icon === 'mdi-linkedin'"  alt="" class="socc-icon linkedin s-icons" contain />
                          <v-img src="@/assets/img/instagram.png" v-if="soc.icon === 'mdi-instagram'"   alt="" class="socc-icon instagram s-icons" contain/>
                          <v-img src="@/assets/img/tiktok.png" v-if="soc.icon === 'mdi-tiktok'" alt="" class="socc-icon tiktok s-icons" contain/>
                        </div>
                      </a>
                    </template>
                    <template v-else>
                      <div class="footer-icons"  :key="i" >
                        <v-icon class="f-s-icons s-icons" :class="soc.icon.replace('mdi-','')" v-if="soc.icon !== 'mdi-tiktok' && soc.icon !== 'mdi-instagram' && soc.icon !== 'mdi-whatsapp'  && soc.icon !== 'mdi-whatsapp'"  >{{ soc.icon }}</v-icon>
                        <v-img src="@/assets/img/whatsapp.png" v-if="soc.icon === 'mdi-whatsapp'" alt="" class="socc-icon s-icons" contain/>
                        <v-img src="@/assets/img/instagram.png" v-if="soc.icon === 'mdi-instagram'" alt="" class="socc-icon instagram s-icons" contain />
                        <v-img src="@/assets/img/tiktok.png" v-if="soc.icon === 'mdi-tiktok'"  alt="" class="socc-icon tiktok s-icons" contain/>
                        <v-img src="@/assets/img/linkedin.png" v-if="soc.icon === 'mdi-linkedin'"  alt="" class="socc-icon linkedin s-icons" contain />
                      </div>
                    </template>
                  </template>
                </div>
              </v-row>
            </v-col>


          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">

        <v-col class="col-10 pb-10 footer-disclaimer">
          Disclaimer: <a href="https://propertyinnepal.com.np" class="footer-link" target="_blank">propertyinnepal.com.np </a> is a Real Estate Marketplace and an Information Hub to help the Real Estate Agencies, Private Seller and Buyers, Developers, and doesn't control in any manner any transactions between the Seller and the Agent or buyer. The details displayed on the website are for informational purposes only. Information regarding real estate projects including property/project details, listings, floor area, location data has been sourced from multiple sources on best effort basis. Nothing contained herein shall be deemed to constitute legal advice, solicitations, marketing, offer for sale, invitation to offer, invitation to acquire by any entity.
        </v-col>

      </v-row>

      <v-row justify="center">
        <v-col col="12" class="col-12 text-center company-footer">
          Property In Nepal &copy; 2023
          <span v-if="$vuetify.breakpoint.mdAndDown"> <br></span>
          <span v-else>,</span>
          All Rights Reserved
          <span v-if="$vuetify.breakpoint.mdAndDown"> <br></span>
          <span v-else>|</span>
          Made with
          <v-icon class="red-heart">mdi-heart</v-icon> by
          <a href="https://nth.com.np/" target="_blank">
            Nakshatra Techno Hub
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      socials:{
        facebook:{
          icon:'mdi-facebook',
          link:'',
        },
        youtube:{
          icon:'mdi-youtube',
          link:'',
        },
        whatsapp:{
          icon:'mdi-whatsapp',
          link:'',
        },
        instagram:{
          icon:'mdi-instagram',
          link:'',
        },
        tiktok:{
          icon:'mdi-tiktok',
          link:'',
        },
        linkedin:{
          icon:'mdi-linkedin',
          link:'',
        },

      },
      financeLinks: [
        {
          title: "Home Loan",
        },
        {
          title: "Personal Loan",
        },
        {
          title: "Commercial Loan",
        },
        {
          title: "Line of Credit",
        },
        {
          title: "Asset Finance",
        },
        {
          title: "Relocation Loan",
        },
      ],
      resourcesLinks: [

        {
          title: "Unit Converter",
          link: "unitConverter",
        },
        {
          title: "EMI Calculator",
          link: "emiCalculator",
        },
      ],
      quickLinks: [
        {
          title: "Sale",
          link: "?propertyFor=Sale",
        },
        {
          title: "Rent",
          link: "?propertyFor=Rent",
        },
        {
          title: "Blog",
          link: "blogs",
        },

      ],
      contactLinks: [
        {
          title: "Contact",
          link: "about-us#contact-us",
        },
        {
          title: "Property Request Form",
          link: "propertyRequest",
        },
      ],
    };
  },
  computed:{
    ...mapState(['contact_details']),
  },
  mounted() {
    Object.entries(this.contact_details).forEach(([key, value]) => {
      if (Object.prototype.hasOwnProperty.call(this.socials, key)) {
        this.socials[key].link = value;
      }
    });
  },
};
</script>

<style scoped>
.p-footer {
  color: black !important;
  background:linear-gradient(90deg, white 0.1%, #00b5e7 100%);
  padding-top: 50px;
}
.p-footer * {
  color: black !important;
}
.p0 {
  padding: 0px !important;
}

.social-icons {
  font-size: 35px !important;
  margin-right: 10px;
  color: black !important;
}
.footer-logo {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: 35px;
  height: 35px;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
}

.mdi-facebook{
  color: #4267B2 !important;
}
.mdi-youtube{
  color: #c4302b !important;
}



.footer-icon-container{
  padding-top: 15px;
}

.company-footer:hover .red-heart{
  color: red !important;
}

.footer-link{
  color: #00b5e7 !important;
  cursor: pointer;
}

.footer-disclaimer{
  font-style: italic;
  font-size: 17px;
}
</style>
