<template>
  <v-container fluid class="p-content p-premium-properties fix-width">
    <v-row>
      <v-col class="col-12 text-center">
        <v-btn class="darkblue--btn"> Premium Properties</v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col class="col-md-11 col-12" v-if="f_premium != null && f_premium.length>0">
        <v-row>
          <Slick ref="slick" class="col-12 px-3" v-bind="propertiesSlickoptions"
                 @swipe="setCarouselSwiping(true)"
                 @mouseup.native="setCarouselSwiping(false)"
                 @touchend.native="setCarouselSwiping(false)"
                 :class="{ '--swiping': swiping === true }"
          >
            <PropertyCard :item="item" v-for="(item, i) in f_premium" :key="i" class="col-12 py-10 px-5"/>

          </Slick>
        </v-row>
      </v-col>
      <v-col class="col-md-11 col-11" v-else>
        <p class="text-center text-h5">
          No Properties Found
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Slick from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import {mapState} from "vuex";
import PropertyCard from "@/components/PropertyCard";
export default {
  name: "PremiumComponent",
  components: {
    Slick, PropertyCard
  },
  data() {
    return {
      tab: 1, // Define the reactive property here
        swiping:false,
      propertiesSlickoptions: {
        arrows: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 300,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed:{
    ...mapState(['f_premium'])
  },
  methods: {
    setCarouselSwiping(state) {
      this.swiping = state;
    },
    goToPropertyCompare(item) {
      this.$store.dispatch('compareProperties',item).then(()=>{
          this.$router.push({name: "compareProperty"});
      })
    },
  },
};
</script>

<style>


</style>
