<template>
<div class="bg-error ">
  <Navbar/>


  <v-container fluid class=" e-bg-container" >
    <v-row>
   <v-col class="col-12 " >

<v-row>
  <v-col class="col-md-8 col-12 pt-md-16">
   <v-row>
     <h1 class="error-p-title col-12">
       ERROR 404 - Page Not Found
     </h1>
     <h2 class="error-p-subtitle col-12 pl-md-16">
       <br>
       Oops! <br>
       It seems like the page you are looking for is lost in cyberspace.<br>
       Sorry for the inconvenience.<br>
       You can try navigating back to our homepage and explore other areas of our website.<br><br>

       <v-btn :to="{name:'home'}" class="blue--btn">  Go To Homepage </v-btn>

     </h2>
   </v-row>
  </v-col>
  <v-col class="col-md-4 col-12">
    <v-img src="@/assets/img/error-404.png" class="error-img" contain/>

  </v-col>
</v-row>

   </v-col>

    </v-row>

    <transition name="fade" duration="3000">
    <v-row v-if="topListings.length > 0" justify="center">
      <v-col class="col-12 ">
        <p class="e-top-l-title mb-0">
         Check out Most popular properties
        </p>
      </v-col>

      <v-col class="col-11">
        <v-row>
          <v-col
              class="col-lg-3 col-md-4 col-sm-6 col-12 filter-card-container pa-2"
              v-for="(item,n) in topListings.slice(0,4)"
              :key="n"
              @click="$router.push({ name: 'singleProperty',params:{slug:item.slug} })"

          >
            <PropertyCard :item="item"/>
          </v-col>
        </v-row>
      </v-col>


    </v-row>
    </transition>

  </v-container>



</div>
</template>

<script>
import Navbar from "@/components/Navbar";
import {mapState} from "vuex";
import PropertyCard from "@/components/PropertyCard";

export default {
  metaInfo(){
    return{
      title:'404 - Page Not Found',
      titleTemplate:this.$root.$options.titleTemplate,
    }
  },
  name: "Error",
  components:{
    PropertyCard,
    Navbar,
  },
  computed:{
    ...mapState(['topListings']),
  }
}
</script>

<style scoped>

.bg-error{
  height:100%;
  overflow: scroll;
  background-color: rgba(108, 25, 25, 0.23);
font-family: Roboto;

}
.e-bg-container{
  background-size: 20%;
  /*background-position: center;*/
}

.error-p-title{
font-size: 1.8em;
  text-align: center;
}
.error-p-subtitle {
  font-size: 1.6em;
  text-align: left;
}

.error-p-subtitle .blue--btn{
  text-align: center;
}

.e-top-l-title{
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
}

.error-img{
  height: 85%;
}

</style>