<template>
  <div>




    <!--Contact US Text-->
    <v-container fluid>
      <v-row justify="center">
        <v-col class="col-10">
          <div class="my-10 text-center">
            <p class="text-h4 pa-2 font-weight-bold">
              If You Have Any Query, Feel Free To
              Contact Us
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!--Form-->
    <v-container fluid>
      <v-row dense justify="center">
        <v-col class="col-md-11 col-12">
          <v-row>
            <v-col class="col-md-5 col-12">
              <v-row class="circle-div" justify="center">
                <div class="circle3 hidden-md-and-down"></div>
                <v-col v-for="card in cards" :key="card.title" class="col-md-10 col-12">
                  <v-card tile outlined class="my-3">
                    <v-row>
                      <v-col cols="2">
                        <div class="icon-container">
                          <v-icon class="icon-background" color="white" size="40">
                            {{ card.icon }}</v-icon>
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <v-row>
                          <v-col class="py-0">
                            <v-card-title
                                v-text="card.title"
                                class="text-h6 font-weight-bold pb-0 mt-1"
                            ></v-card-title>
                            <v-card-text
                                class="font-weight-bold text-subtitle-2 black--text"
                                v-text="card.subTitle"
                            ></v-card-text>
                          </v-col>
                        </v-row>
                      </v-col>

                    </v-row>
                  </v-card>
                </v-col>

                <v-col class="col-md-12 col-12" v-if="requestSuccess">
                  <v-alert
                      type="success"   variant="tonal">
                    Thank you for contacting us!
                  </v-alert>
                </v-col>

              </v-row >
            </v-col>

            <v-col class="col-md-7 col-12 circle-div">
              <div class="circle1 hidden-md-and-down"></div>
              <div class="circle2 hidden-md-and-down"></div>
              <v-row >
                <v-col >
                  <ValidationObserver ref="contactForm" v-slot="{ handleSubmit }">

                    <form
                        ref="requestForm"
                        method="post"
                        @submit.prevent="handleSubmit(initiateRequest)"
                    >
                      <v-card outlined>
                        <v-row  class="ma-4">
                          <v-col cols="12" sm="6" md="6">
                            <ValidationProvider rules="name" v-slot="{ errors }">
                              <v-text-field
                                  v-model="contact.name"
                                  label="Your Name"
                                  :error-messages="errors[0]"
                                  :hide-details="true"
                                  filled
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                v-model="contact.email"
                                label="Your Email"
                                filled
                                :hide-details="true"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mx-4">
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                v-model="contact.location"
                                label="Location"
                                filled
                                :hide-details="true"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                v-model="contact.budget"
                                label="Budget"
                                filled
                                :hide-details="true"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mx-4">
                          <v-col cols="12">
                            <v-textarea
                                v-model="contact.message"
                                label="Message"
                                rows="2"
                                filled
                                :hide-details="true"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-list-item>
                            <v-row justify="center" class="pt-7 pb-8">
                              <v-btn
                                  color="#00BBE4"
                                  elevation="2"
                                  tile
                                  x-large
                                  type="submit"
                                  class="white--text ">Send</v-btn>
                            </v-row>
                          </v-list-item>
                        </v-card-actions>
                      </v-card>
                    </form>
                  </ValidationObserver>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!--Socials-->
    <v-container class="text-center my-8" style="color: #1e4d62">
      <v-row>
        <v-col>
          <p class="text-h3 font-weight-bold">Connect With Via Socials</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="auto" class="my-8 d-flex flex-wrap justify-center">
          <div
              class="social-container mx-8"
              v-for="(soc, n) in socials"
              :key="n"
          >
            <template v-if="soc.link">
              <a :href="soc.link" target="_blank" class="pt-3">
                <v-icon v-if="soc.icon !== 'mdi-tiktok'" class="social" size="50">
                  {{ soc.icon }}
                </v-icon>
                <v-responsive v-if="soc.icon === 'mdi-tiktok'">
                  <svg
                      fill="#1E4D62"
                      width="64px"
                      height="64px"
                      viewBox="-90 -150 972.80 972.80"
                      id="icons"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#1E4D62"
                      stroke-width="0.00512"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke="#CCCCCC"
                        stroke-width="6.144"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                          d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                      ></path>
                    </g>
                  </svg>
                </v-responsive>
              </a>

            </template>
            <template v-else>
              <v-icon v-if="soc.icon !== 'mdi-tiktok'" class="social" size="50">
                {{ soc.icon }}
              </v-icon>
              <v-responsive v-if="soc.icon === 'mdi-tiktok'">
                <svg
                    fill="#1E4D62"
                    width="64px"
                    height="64px"
                    viewBox="-90 -150 972.80 972.80"
                    id="icons"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#1E4D62"
                    stroke-width="0.00512"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke="#CCCCCC"
                      stroke-width="6.144"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                        d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                    ></path>
                  </g>
                </svg>
              </v-responsive>
            </template>

          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row class="help">
        <v-col class="hidden-sm-and-down col-6 pa-0"
        ><v-img
            src="./../assets/img/man.png"
            aspect-ratio="0.75"
            max-width="25rem"
            class="offset-md-4"
            contain
        ></v-img
        ></v-col>
        <v-col class="col-md-6 col-12">
          <p class="text-h4 font-weight-bold">How can we help you?</p>
          <p class="font-weight-bold">
        Whether you're looking to buy, sell, or rent a property, we're here to help make your experience smooth and successful. <br/> Feel free to let us know your specific needs, and we'll provide you with the best options available. Ready to embark on this real estate journey together? Let's get started!
          </p>
          <v-row>
            <v-col
                class="font-weight-bold  col-6"
                v-for="(item, key) in list"
                :key="key"
            >
              <p>
                <v-icon color="white" size="30">mdi-check</v-icon>
                {{ item }}
              </p>

            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                  color="#00BBE4"
                  elevation="2"
                  tile
                  x-large
                  class="white--text"
              >Get Started</v-btn
              >
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-container>

    <!--    MAP-->
    <!--    <v-container fluid>-->
    <!--      <v-row>-->
    <!--        <v-col class="col-12 map-container" >-->
    <!--        <div id="map"></div>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </v-container>-->
  </div>
</template>
<script>

import { ValidationObserver, ValidationProvider,extend ,configure } from 'vee-validate';
import { required ,numeric} from 'vee-validate/dist/rules'
import {mapState} from "vuex";

configure({
  validateOnInput: true,
  validateOnChange: true,
  validateOnBlur: true,
  generateMessage: context => {
    const messages = {
      required: '{_field_} is required',
      ...context.messages
    };
    return messages[context.rule.name](context);
  }
});

extend('name', {
  ...required,
  message: 'Name is required'
});

extend('phone', {
  ...required,
  ...numeric,
  message: 'Contact number must be numeric'
});





export default {
  components: { ValidationObserver, ValidationProvider },
  name:'ContactPage',

  data() {
    return {
      map:null,
      marker: null,
      logo: "false",
      contact: {
        name: "",
        email: "",
        location: "",
        budget: "",
        message: "",
      },
      titles: {
        mainTitle: "Contact Us",
        subTitle: "Find Your Property With Us",
      },
      cards: [
        {
          title: "Phone Number",
          subTitle: "",
          icon: "mdi-cellphone",
          flex: 3,
        },
        {
          title: "Email",
          subTitle: "",
          icon: "mdi-email",
          flex: 3,
        },
        {
          title: "Location",
          subTitle: "",
          icon: "mdi-map-marker",
          flex: 3,
        },
      ],
      socials:{
        facebook:{
          icon:'mdi-facebook',
          link:'',
        },
        youtube:{
          icon:'mdi-youtube',
          link:'',
        },
        whatsapp:{
          icon:'mdi-whatsapp',
          link:'',
        },
        instagram:{
          icon:'mdi-instagram',
          link:'',
        },
        tiktok:{
          icon:'mdi-tiktok',
          link:'',
        },
        linkedin:{
          icon:'mdi-linkedin',
          link:'',
        },

      },
      list: {
        item1: "Rental Apartment",
        item2: "Buy Apartment",
        item3: "Sale House",
        item4: "Buy House",
        item5: "Sale Land",
        item6: "Buy Land",
      },
      requestSuccess:false,
    };
  },
  computed:{
    ...mapState(['contact_details']),
  },
  mounted(){
    this.cards[0].subTitle = this.contact_details.contact;
    this.cards[1].subTitle = this.contact_details.email;
    this.cards[2].subTitle = this.contact_details.address;

    Object.entries(this.contact_details).forEach(([key, value]) => {
      if (Object.prototype.hasOwnProperty.call(this.socials, key)) {
        this.socials[key].link = value;
      }
    });
  },
  methods:{
    initiateRequest(){
      this.requestSuccess = false;
      this.$refs.contactForm.validate().then(result => {
        if (result) {
          this.$axios.post('contact',this.contact)
              .then(() => {
                this.requestSuccess = true;
                this.contact =  {
                  name: "",
                  email: "",
                  location: "",
                  budget: "",
                  message: "",
                };
                this.$refs.contactForm.reset();
              })
        }
      });
    }
  }
};
</script>
<style scoped>

.circle-div {
  position: relative;
}
.circle1 {
  position: absolute;
  top: -80px;
  right: -80px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #e3f6fa;
  z-index: 0;
}
.circle2 {
  position: absolute;
  bottom: -38px;
  left: -38px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e3f6fa;
  z-index: 0;
}
.circle3 {
  position: absolute;
  top: 0;
  left: -10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e3f6fa;
  z-index: 0;
}
.icon-container {
  display: inline-block;
  padding: 10px;
  position: relative;
}

.icon-background {
  font-size: 24px;
  background-color: #1e4d62;
  padding: 10px;
  position: relative;
  z-index: 1;
  width: 1.5em;
  height: 1.5em;
}
#icons {
  height: 6em;
  width: 6em;
  display: flex;
  justify-content: center;
  top: 0;
}
.social-container {
  background-color: #e3f6fa; /* Specify your desired dark background color */
  padding: 10px;
  margin: 5px;
  border-radius: 50%;
  height: 6em;
  width: 6em;
  display: flex;
  justify-content: center;
}

.social {
  color: #1e4d62; /* Specify your desired icon color */
}

.help {
  background-color: #1e4d62;
  color: #efefef;
  justify-content: center;
  align-items: center;
}

.map-container{
  margin:0 !important;
  padding:0 !important;
}

#map{
  height: 600px;
  overflow: hidden;
}

div.social-container:nth-child(5) > a:nth-child(1) > div:nth-child(1) {
  height: 67px;
  width: 73px;
  margin-top: -13px;
}

</style>
