<template>
  <v-row justify="center" class="featured">
    <v-col class="col-md-11 col-12">
      <v-tabs v-model="tab" color="deep-purple-accent-4 " centered>
        <v-tab
            v-for="(property, index) in compiledFeatured"
            :key="index"
            :value="index"
            @click="gotoStart"
            class="text-capitalize"
        >
          {{ property.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="(category, index) in paginatedProperties"
            :key="index"
            :value="index"
        >
          <v-container fluid>
            <v-row>
              <v-col class="col-md-12">
                <v-row
                    v-if="category.properties.length>0"
                >
                  <v-col
                      v-for="(item, i) in category.properties"
                      :key="i"

                      class="property-card-container col-12 col-md-6 col-lg-3"
                  >
<!--                    <v-container fluid>-->
<!--                      <v-row>-->
<!--                        <v-col class="col-12 property-card">-->
<!--                          <router-link-->
<!--                              :to="{ name: 'singleProperty' ,params:{slug:item.slug}}"                              class="normalizeLink"-->
<!--                          >-->
<!--                            <v-row>-->
<!--                              <div class="feature-property-card-image">-->
<!--                                <v-img-->
<!--                                    :src="item.images.length>0?item.images[0]:`https://picsum.photos/500/300?image=${-->
<!--                                    i * index * 5 + 10-->
<!--                                  }`"-->

<!--                                    height="100%"-->
<!--                                    width="100%"-->
<!--                                ></v-img>-->
<!--                                <div class="sale-card-tag">-->
<!--                                  {{ item.for }}-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                              <v-col class="col-12 feature-property-details">-->
<!--                                <v-row>-->
<!--                                  <v-col class="col-12 py-2 pb-1">-->
<!--                                    <p class="p-title mb-1" :title="item.name">-->
<!--                                      {{ item.name.length > 26 ? item.name.slice(0, 26) + '..' : item.name }}-->
<!--                                    </p>-->
<!--                                  </v-col>-->

<!--                                  <v-col class="col-12 py-2 pb-1">-->
<!--                                    <p class="p-location" :title="item.location">-->
<!--                                <span class="float-left">-->
<!--                                <v-icon class="pin-icon"> mdi-map-marker</v-icon>-->
<!--                                {{ item.location.length > 25 ? item.location.slice(0, 25) + '..' : item.location }}-->
<!--                                </span>-->

<!--                                      <span class="pin-code float-right">-->
<!--                                  {{item.code}}-->
<!--                                </span>-->
<!--                                    </p>-->
<!--                                  </v-col>-->

<!--                                  <v-col class="col-12 py-2 pb-1">-->
<!--                                    <p class="property-price p-price mb-0">-->
<!--                                      NPR. {{ $textifyPrice(item.price) }}-->
<!--                                    </p>-->
<!--                                    <p class="p-time mb-0">-->
<!--                                      {{ item.created_at_human }}-->
<!--                                    </p>-->
<!--                                  </v-col>-->
<!--                                </v-row>-->





<!--                              </v-col>-->
<!--                            </v-row>-->
<!--                          </router-link>-->
<!--                          <v-row>-->
<!--                            <v-col class="col-12 feature-property-details-btn">-->
<!--                              <v-btn-->
<!--                                  class="p-blue&#45;&#45;btn"-->
<!--                                  @click="goToPropertyCompare(item)"-->
<!--                              >-->
<!--                                Compare-->
<!--                                <v-icon class="featured-compare">mdi-compare-horizontal</v-icon>-->

<!--                              </v-btn-->
<!--                              >-->
<!--                              <v-btn-->
<!--                                  class="p-orange&#45;&#45;btn"-->
<!--                                  :to="{ name: 'singleProperty' ,params:{slug:item.slug}}"                              >-->
<!--                                Learn More-->
<!--                              </v-btn>-->
<!--                            </v-col>-->
<!--                          </v-row>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </v-container>-->

                    <PropertyCard :item="item" />
                  </v-col>
                </v-row>
                <v-row v-else>
<v-col class="col-12 pa-10">
  <p class="text-center text-h5">
    No Properties Found
  </p>
</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <!-- Pagination controls -->
          <v-row>
            <v-col cols="12" class="text-center">
              <v-pagination
                  v-if="category.properties.length>0"
                  v-model="currentPage"
                  @input="changePage"
                  :length="totalPageCount(index)"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from "vuex";
import PropertyCard from "@/components/PropertyCard";
export default {
  name: "FeaturedComponent",
  components: {PropertyCard},

  data() {
    return {
      tab: 0, // Define the reactive property here
      currentPage: 1,
      itemsPerPage: 4,
    };
  },
  computed: {
    ...mapState(['f_featured']),
    // eslint-disable-next-line vue/return-in-computed-property

    compiledFeatured() {
    //Land For Rent in last
      let f_Featured = [];
      if (this.f_featured != null) {
        if (this.f_featured.sale) {
          for (const saleKey in this.f_featured.sale) {
            const category = {
              title: `${saleKey} For Sale`,
              properties: this.f_featured.sale[saleKey]
            };
            f_Featured.push(category);
          }
        }


        if (this.f_featured.rent) {
          for (const rentKey in this.f_featured.rent) {
            const category = {
              title: this.dynamicTitle(rentKey) ,
              properties: this.f_featured.rent[rentKey]
            };
            f_Featured.push(category);
          }
        }
      }


// Find the index of the element with title 'y for y'
      const index = f_Featured.findIndex(item => item.title.toLowerCase() === 'land for lease');

// Move the element to the last position
      if (index !== -1) {
        const removedItem = f_Featured.splice(index, 1)[0];
        f_Featured.push(removedItem);
      }


      return f_Featured;


    },

    dynamicTitle(){
      return (title) => {
        if (title == 'land'){
          return title + ' for Lease';
        }
        return title + ' for Rent';
      };

    },

    paginatedProperties() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      const paginatedProperties = [];

      this.compiledFeatured.forEach((featuredProperty) => {
        paginatedProperties.push({
          title: featuredProperty.title,
          properties: featuredProperty.properties.slice(startIndex, endIndex),
        });
      });

      return paginatedProperties;
    },


  },
  mounted() {

  },
  methods: {
    goToPropertyCompare(item) {
      this.$store.dispatch('compareProperties',item).then( ()=>{
          this.$router.push({name: "compareProperty"});
      })
    },
    gotoStart() {
      this.currentPage = 1;
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    totalPageCount(categoryIndex) {
      //const categoryIndex = 5; // Replace 0 with the index of the category you want to get the count for
      const categoryProperties =
          this.compiledFeatured[categoryIndex].properties;
      return Math.ceil(categoryProperties.length / this.itemsPerPage);
    },
  },
};
</script>

<style>
.property-card-container {
  padding: 20px;
}



.v-tabs-bar {
  height: auto;
}

.v-tab {
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 10px;
  min-height: 32px;
}

.v-tab--active {
  background: #184a62 !important;
  color: white !important;
}

.v-tabs-slider-wrapper {
  display: none;
}

.v-slide-group__prev--disabled {
  display: none !important;
}

.featured-compare{
  font-size: 20px !important;
  padding-left:5px;
}




.theme--light.v-pagination .v-pagination__item--active {
  background: #399ece !important;
}

</style>
