<template>
  <v-container fluid class="blogs" v-if="hashtagBlogs.length > 0 ">
    <v-row>
      <v-col>
        <v-row>
          <v-col v-for="post in hashtagBlogs" :key="post.id" cols="12" md="4">
            <BlogCard :post="post" />
          </v-col>
        </v-row>


        <v-row>
          <v-col class="col-12" v-if="pagination.total > 1">
            <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="pagination.totalVisible"
                @input="fetchBlogs"
            ></v-pagination>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>

  <v-container fluid v-else>
    <v-row>
      <v-col class="col-12">
        <div style="text-align: center;width: 100%" v-if="isLoading">
          <v-progress-circular
              indeterminate
              :size="70"
              :width="7"
              color="#00bbe4"
          ></v-progress-circular>
        </div>


        <div class="col-12 empty-data-container" v-else>

          <div class="d-flex justify-center">

            <div class="no-image-container">
              <v-img src="@/assets/img/not-found.png" cover/>
            </div>

          </div>

          <p class="text-h5 text-center">
            No blogs found
          </p>

        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BlogCard from "@/components/BlogCard";
import { mapState } from "vuex";
import store from "@/store";
import Vue from "vue";

export default {
  name: "blogHashtag",
  metaInfo(){
    return{
      title:'#'+this.hashtag,
      titleTemplate: this.$root.$options.titleTemplate,
    }
  },
  components: {
    BlogCard,
  },
  data() {
    return {
      hashtag: "",
      pagination: {
        current: 1,
        total: 0,
        totalVisible:7,
      },
    };
  },
  created() {
    this.hashtag = this.$route.params.hashtag;
  },

  beforeRouteEnter(to,from,next){
    next(vm=>{
      if (to.params.hashtag) {

        if (store.state.hashtags== null){
          store.dispatch('fetchHashtags');
          store.dispatch('fetchCategories');
          store.dispatch('fetchRecentBlogs');
        }

        store.dispatch('fetchBlogsByHashtag', [to.params.hashtag, vm.pagination.current]).then(response => {
          vm.pagination.total = response.last_page
          vm.pagination.current = response.current_page
        });
      }
      })
  },
  computed: {
...mapState(['hashtagBlogs','isLoading']),
  },
  watch: {
    "$route.params.hashtag": function () {
      this.hashtag = this.$route.params.hashtag;
      this.fetchBlogs();
    },
  },
  methods:{
    fetchBlogs(){
      this.$store.commit('SET_HASHTAG_BLOGS',[]);

      this.$store.dispatch('fetchBlogsByHashtag',[this.$route.params.hashtag,this.pagination.current]).then(response=>{
        this.pagination.current = response.current_page
        this.pagination.total = response.last_page
        Vue.prototype.$Progress.finish();

      });
    }
  }
};
</script>
