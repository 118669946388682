<template>
  <div>
    <Navbar/>
    <v-container fluid class="blogs">
      <v-row >

        <BlogSide  v-if="$route.name !=='blogPost'" class="order-md-1 order-1"  />


        <v-col cols="12" :md="$route.name !=='blogPost'?'9':'12'" id="scrollContainer" class="pa-0 order-md-2 order-0">
          <transition
              name="fade"
          >
          <router-view/>
          </transition>
        </v-col>
      </v-row>
    </v-container>
    <Footer/>
    <SocialIcons/>


  </div>

</template>

<script>
import BlogSide from "@/components/BlogSide";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import SocialIcons from "@/components/SocialIcons";

export default {
  name: "BlogLayout",
  components:{
    BlogSide,Navbar,Footer,SocialIcons
  },


}
</script>

<style scoped>

</style>