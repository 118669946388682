<template>
  <div class="mf-CEO-mf">
    <div class="circle-overlay"></div>
    <div class="circle-overlay2"></div>

    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <p class="mf-ceo-title"> Message From CEO</p>
        </v-col>
        <v-col class="col-12">
          <v-row>
            <v-col class="col-md-5 col-12 " >
              <v-row justify="center">
                <v-col class="col-md-8 col-12">
                  <div class="CEO-image" :style="$inlineBgImage(messageFromCEO.image)" contain>
                    <div class="CEO-image-overlay">
                      <a :href="messageFromCEO.fb" v-if="messageFromCEO.fb">
                        <v-icon class="msg-fb-icon">mdi-facebook</v-icon>
                      </a>
                      <a :href="messageFromCEO.linkedin" v-if="messageFromCEO.linkedin">
                        <v-icon class="msg-li-icon">mdi-linkedin</v-icon>
                      </a>
                    </div>
                  </div>



                </v-col>
              </v-row>
            </v-col>
            <v-col class="col-md-7 col-12 pr-0 CEO-says ">
              <h2 class="welcome-mssg"> WELCOME MESSAGE FROM</h2>
              <h3 class="to-new-client"> CEO TO NEW CLIENT</h3>
              <h5 class="welcome-text"> Welcome Message</h5>
              <p> {{messageFromCEO.description}}</p>
              <p class="ceo-name"> {{ messageFromCEO.name }} </p>
              <p class="ceo-again"> CEO & Founder</p>


            </v-col>


          </v-row>


        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "MessageFromCEO",
  computed:{
    ...mapState(['messageFromCEO'])
  }
}
</script>

<style scoped>

.mf-CEO-mf{
  padding:20px 0px 20px 0px;
  position: relative;
}
.CEO-image {
  position: relative;
  min-height: 200px;
  height: 370px;
  width: 100%;
  max-width: 400px !important;
  border-radius: 15px;
  box-shadow: 2px 2px 2px black;
  margin:0 auto !important;

  background-size: cover;
  background-position: center;
  z-index: 2 !important;


}

.CEO-image-overlay{
  position: absolute;
  display: none;
  height: auto;
  width: auto;
  padding:5px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  bottom: 5px;
  right: 5px;
  backdrop-filter: blur(1px);
}

.CEO-image:hover .CEO-image-overlay{
  display: block;
}

.circle-overlay{
  position: absolute;
  background: linear-gradient(210deg, #2281e7 0.9%, rgba(0, 231, 220, 0.59) 100%);
height: 550px;
  width: 550px;
  top: 15%;
  left: -12%;
  border-radius: 50%;
  z-index: 1;
  filter: opacity(0.8);
}
.circle-overlay2{
  position: absolute;
  background:linear-gradient(210deg, white 0.9%, rgba(0, 181, 231, 0.59) 100%);
  backdrop-filter: blur(4px);
  height: 250px;
  width: 250px;
  bottom: -20%;
  right: -1%;
  border-radius: 50%;
  z-index: 0;
  filter: opacity(0.5);

}

.mf-ceo-title {
  padding-top: 20px;
  font-size: 39px;
  text-align: center;
  padding-bottom: 50px !important;
  font-weight: bold;
  font-family: 'Roboto',sans-serif;
  color: rgb(30, 77, 98);

}

.CEO-says {
  background:linear-gradient(130deg, rgba(255, 255, 255, 0.7) 0.1%, rgba(0, 181, 231, 0.09)2%,rgba(0, 181, 231, 0.59) 100%);
  border-radius: 56px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left:40px;
  padding-top:20px;
  z-index: 3 !important;
  backdrop-filter: blur(2px);
  /*padding-bottom: 30px;*/

}




.CEO-says .welcome-mssg {
  font-weight: bold;
  font-size: 2.8em;
  background: black;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 17px;

}
.CEO-says .to-new-client {
  font-weight: bold;
  font-size: 30px;
  background: linear-gradient(to bottom, #2283c1, #31a4b0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.CEO-says .welcome-text {
  font-weight: bold;
  font-size: 20px;
  padding-left: 20px;
  padding-top: 25px;
  padding-bottom: 2px;
  margin-bottom:0;
}
.CEO-says .ceo-name {
  font-weight: bold;
  font-size: 20px;
  padding-left: 20px;
  padding-bottom: 0;
  margin-bottom:0;
  padding-top: 15px;

}
.CEO-says .ceo-again {
  font-weight: bold;
  font-size: 12px;
  padding-left: 20px;
  padding-bottom: 0;
  margin-bottom:0;
}
.CEO-says p {
  font-size: 18px;
  padding: 0px 20px;
}



/* Inline #58 | http://localhost:8080/about-us */

.CEO-says .ceo-name[data-v-e54e9328] {
  color: #1e4d62;
}

.CEO-says .welcome-text[data-v-e54e9328] {
  color: #1f1f1f;
}

.CEO-says p[data-v-e54e9328] {
  color: #030303;
}

.msg-fb-icon,.msg-li-icon{
  font-size: 2.5em;
  margin-right:5px;
}




</style>