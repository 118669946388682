<template>

  <div class="overlay-social-icons" v-show="$route.name === 'home' && $vuetify.breakpoint.mdAndUp">
<!--  <div class="overlay-social-icons" v-show="showSocials && $route.name=='home' && $vuetify.breakpoint.mdAndUp">-->
    <template v-for="(soc,i) in socials">
      <template v-if="soc.link">
        <a :href="soc.link" target="_blank" :key="i">
          <div class="kontainer"  :key="i" >
            <v-icon class="social-icons s-icons" :class="soc.icon.replace('mdi-','')" v-if="soc.icon !== 'mdi-tiktok' && soc.icon !== 'mdi-instagram' && soc.icon !== 'mdi-whatsapp'  && soc.icon !== 'mdi-linkedin' "  >{{ soc.icon }}</v-icon>
            <v-img src="@/assets/img/whatsapp.png" v-if="soc.icon === 'mdi-whatsapp'"  alt="" class="socc-icon whatsapp s-icons" contain />
            <v-img src="@/assets/img/linkedin.png" v-if="soc.icon === 'mdi-linkedin'"  alt="" class="socc-icon linkedin s-icons" contain />
            <v-img src="@/assets/img/instagram.png" v-if="soc.icon === 'mdi-instagram'"   alt="" class="socc-icon instagram s-icons" contain/>
            <v-img src="@/assets/img/tiktok.png" v-if="soc.icon === 'mdi-tiktok'" alt="" class="socc-icon tiktok s-icons" contain/>
          </div>
        </a>
      </template>
      <template v-else>
        <div class="kontainer"  :key="i" >
          <v-icon class="social-icons s-icons" :class="soc.icon.replace('mdi-','')" v-if="soc.icon !== 'mdi-tiktok' && soc.icon !== 'mdi-instagram' && soc.icon !== 'mdi-whatsapp'  && soc.icon !== 'mdi-whatsapp'"  >{{ soc.icon }}</v-icon>
          <v-img src="@/assets/img/whatsapp.png" v-if="soc.icon === 'mdi-whatsapp'" alt="" class="socc-icon s-icons" contain/>
          <v-img src="@/assets/img/instagram.png" v-if="soc.icon === 'mdi-instagram'" alt="" class="socc-icon instagram s-icons" contain />
          <v-img src="@/assets/img/tiktok.png" v-if="soc.icon === 'mdi-tiktok'"  alt="" class="socc-icon tiktok s-icons" contain/>
          <v-img src="@/assets/img/linkedin.png" v-if="soc.icon === 'mdi-linkedin'"  alt="" class="socc-icon linkedin s-icons" contain />

        </div>

      </template>

    </template>
  </div>


</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      showSocials: false,
      socials:{
        facebook:{
          icon:'mdi-facebook',
          link:'',
        },
        youtube:{
          icon:'mdi-youtube',
          link:'',
        },
        whatsapp:{
          icon:'mdi-whatsapp',
          link:'',
        },
        instagram:{
          icon:'mdi-instagram',
          link:'',
        },
        tiktok:{
          icon:'mdi-tiktok',
          link:'',
        },
        linkedin:{
          icon:'mdi-linkedin',
          link:'',
        },

      },
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    Object.entries(this.contact_details).forEach(([key, value]) => {
      if (Object.prototype.hasOwnProperty.call(this.socials, key)) {
        this.socials[key].link = value;
      }
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed:{
    ...mapState(['contact_details']),
  },
  watch:{
    contact_details(){
        Object.entries(this.contact_details).forEach(([key, value]) => {
          if (Object.prototype.hasOwnProperty.call(this.socials, key)) {
            this.socials[key].link = value;
          }
        });
    },
  },
  methods: {
    handleScroll() {
      // Show the button when user scrolls beyond a certain threshold (e.g., 300px)
      this.showSocials = window.pageYOffset > 520;
    },
    scrollToTop() {
      // Smoothly scroll to the top of the page
    }
  }
};
</script>

<style scoped>
.kontainer:hover{
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  border-radius: 6px;
}
</style>