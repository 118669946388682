<template>
  <div>
    <HeroHeader :titles="titles" />
    <div style="position: relative;">

    </div>
    <div ref="myDiv" id="viewer"></div>
    <SearchComponent @search="searchProperties"/>

    <v-container class="text-center btn--margin">
      <v-btn tile class="darkblue--btn"> Top Listing</v-btn>
    </v-container>

    <v-container fluid class="p-content">
      <v-row justify="center">
        <v-col class="col-md-11 col-11">
          <v-row>

            <v-col
                class="col-lg-3 col-md-4 col-sm-6 col-12 filter-card-container pa-4"
                v-for="(item,n) in topListings"
                :key="n"
                @click="$router.push({ name: 'singleProperty',params:{slug:item.slug} })"

            >

              <PropertyCard :item="item"/>

            </v-col>


          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Popular Locations Div-->
    <v-container fluid class="discover">
      <v-row>
        <v-col class="text-center my-12 col-md-6 col-12">
          <v-icon color="white" size="60" class="mx-2" v-for="i in 3" :key="i"
            >mdi-star</v-icon
          >
          <p class="text-h5 font-weight-bold my-4">Popular Locations</p>
        </v-col>
        <v-col class="my-6 col-12 col-md-6">
          <p class="text-h4 font-weight-bold">Discover Towns and Cities</p>
          <p class="text-subtitle-1 font-weight-bold">

          </p>
          <v-row>
            <v-col
              class="font-weight-bold text-h6 col-md-6 col-sm-12 col-12 pb-sm-2"
              v-for="(item, key) in list"
              :key="key"
            >
              <v-icon color="white" size="30" class="col-2">mdi-send</v-icon>
              <span class="col-10">

              </span>
              {{ item }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- Premium Properties-->
    <PremiumComponent />
  </div>
</template>
<script>
import PremiumComponent from "@/components/PremiumComponent";
import PropertyCard from "@/components/PropertyCard";
import HeroHeader from "@/components/HeroHeader";
import SearchComponent from "@/components/SearchComponent";
import {mapState} from "vuex";
export default {
  metaInfo() {
    return {
      title: "Top Listing",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
  components: {
    SearchComponent,
    PremiumComponent,
    HeroHeader,
    PropertyCard
  },
  computed:{
    ...mapState(['topListings']),
  },
  data() {
    return {
      titles: {
        mainTitle: "Top Listing",
        subTitle: "Find Your Property With Us",
      },
      tab: 1, // Define the reactive property here

      properties: [
        {
          title: "2BHK Appartment",
          location: "Bishal Nagar, KTM",
          price: "$100,000",
          time: "2 hours ago",
          label: "SALE",
        },
        {
          title: "2BHK Appartment",
          location: "Location 2",
          price: "$150,000",
          time: "3 hours ago",
          label: "RENT",
        },
        {
          title: "2BHK Appartment",
          location: "Location 3",
          price: "$200,000",
          time: "4 hours ago",
          label: "SALE",
        },
        {
          title: "2BHK Appartment",
          location: "Location 3",
          price: "$200,000",
          time: "4 hours ago",
          label: "RENT",
        },
        {
          title: "2BHK Appartment",
          location: "Location 1",
          price: "$100,000",
          time: "2 hours ago",
          label: "SALE",
        },
        {
          title: "2BHK Appartment",
          location: "Location 2",
          price: "$150,000",
          time: "3 hours ago",
          label: "RENT",
        },
        {
          title: "2BHK Appartment",
          location: "Location 3",
          price: "$200,000",
          time: "4 hours ago",
          label: "SALE",
        },
        {
          title: "2BHK Appartment",
          location: "Location 3",
          price: "$200,000",
          time: "4 hours ago",
          label: "RENT",
        },
      ],

      list: {
        city1: "Kathmandu",
        city2: "Butwal",
        city3: "Pokhara",
        city4: "Biratnagar",
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      const targetDiv = this.$refs.myDiv;
      targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  },
  methods:{
    letsCompare(item){
      if (this.$store.state.compare.length<3){
        this.$store.state.compare.push(item);
        this.$router.push("/compare");
      }
    },
    searchProperties(searchWords){
      this.$router.push({path: 'search', query: {words:searchWords}});

    }
  }
};
</script>
<style scoped>
#viewer {
  position: absolute;
  top:27vh;
  height: 1px;
  width: 1px;
}
.discover {
  background-color: #1e4d62;
  color: #efefef;
  padding: 75px;
}
.property-details {
  padding:30px;
}
.p-title {
  align-self: center;
  padding: 0 5px;
}
.p-time {
  padding: 0 5px;
}

.p-location {
  padding: 20px 0;
}

.property-card{
  height: auto !important;
  padding-bottom: 25px;
}

.property-card:hover .property-compare-btn {
  display: inline-block;
}

.filter-card-container{
  cursor: pointer;
}

.s-property-card-image{
  width: 100%;
}

.top-title{
  /*min-height: 34px;*/
  max-height: 30px;
  overflow: hidden;
}


.p-time {
  color: #000;
  font-size: 14px;
  padding-top: 6px;
}

</style>
