<template>
<div>
  <Navbar/>
  <transition
      name="fade"
  >
    <router-view class="page"/>
  </transition>

  <Footer/>
  <SocialIcons/>
</div>
</template>

<script>

import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import SocialIcons from "@/components/SocialIcons";
export default {
  name: 'AppLayout',
  components: {Navbar,Footer,SocialIcons},
  data: () => ({
    //
  }),
};
</script>

<style>
.page {
  max-height: 100%;
  overflow: hidden;
}



</style>
