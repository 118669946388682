<template>
  <v-col class="left-blogs-filter col-12 col-md-3"  :class="stickySideClass">
    <v-row class="">
      <v-col class="col-12 blog-categories-container" v-if="categories.length>0">
        <h3 class="text-color">Categories</h3>
        <v-row>
          <v-col class="col-12">
            <div class="blog-categories">
              <v-list color="transparent">
                <v-list-item
                  v-for="(category,index) in categories"
                  :key="index"
                  class="ma-0 pa-0"
                >
                  <router-link
                    :to="{
                      name: 'blogCategory',
                      params: { slug: category.slug },
                    }"
                    class="w-100 category-target"
                  >
                    <v-col class="col-12">
                      <v-row>
                        <v-icon class="mr-2"> mdi-arrow-right-bottom-bold </v-icon>
                        <span class="flex-grow-1">
                          {{ category.name }}
                        </span>
                        <v-chip class="ml-auto"> {{ category.blogs_count }} </v-chip>
                      </v-row>
                    </v-col>
                  </router-link>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-12 hashtags-container" v-if="hashtags.length>0">
        <h3 class="text-color">#Hashtags</h3>
        <v-row>
          <v-col class="col-12">
            <div class="hashtags">

              <template v-for="(hashtag,index) in hashtags"
                ><router-link
                  :key="index"
                  :to="{ name: 'blogHashTag', params: { hashtag: hashtag.name.replace('#', '') } }"
                >
                  <v-chip class="hashtag-chips"> {{ hashtag.name }} </v-chip>
                </router-link></template
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="recentBlogs.length>0">
      <v-col>
        <h3 class="text-color">Latest Blogs</h3>
        <v-row >
          <v-col class="col-md-10">
          <v-row>
            <v-list-item
                v-for="post in recentBlogs"
                :key="post.id"
                :to="`/blog/${post.slug}`"
            >
              <div class="col-12  col-xs-3 recent-blog">
                <div
                    class="recent-blog-image"
                    :style="$inlineBgImage(
                                  post.banner ? post.banner : `https://picsum.photos/500/300?image=${5 + 10}`
                )"
                ></div>
                <p class="blog-title" :title="post.title">
                  {{ post.title.length > 30 ? post.title.slice(0, 30) + '...' : post.title }}

                </p>
                <p class="blog-date">
                  <span> {{ post.created_at_human }} </span>
                </p>
              </div>
            </v-list-item>
          </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BlogSide",
  data() {
    return {
    };
  },
  computed: {
    ...mapState(["hashtags","categories","recentBlogs"]),
    stickySideClass() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'sticky-side';
      } else {
        return '';
      }
    }
  },

  methods: {

  },
  mounted() {

  },
};
</script>
<style scoped>
.w-100 {
  width: 90%;
}
.left-blogs-filter {
  padding-top: 40px;
  /* background: linear-gradient(-90deg, #dbedff, #00bbe4); */
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
}
.hashtag-chips {
  background: #00bbe4 !important;
  color: rgb(255, 243, 243);
  margin: 2px;
  cursor: pointer;
}
.text-color {
  color: #184a62;
}
.blog-categories-container .v-icon {
  color: #076fb6 !important;
}

.blog-categories .category-target {
  margin: auto !important;
  /*border :1px dotted rgba(0, 0, 0, 0.1);*/
  padding: 5px !important;
  background: linear-gradient(
    90deg,
    rgba(7, 111, 182, 0.008),
    rgba(7, 111, 182, 0.08),
    rgba(7, 111, 182, 0.008)
  );
  color: #076fb6 !important;
  border-radius: 4px;
  cursor: pointer;
}
.blog-categories .category-target:hover {
  background: linear-gradient(
    90deg,
    rgba(7, 111, 182, 0.08),
    rgba(7, 111, 182, 0.08),
    rgba(7, 111, 182, 0.08)
  );
}
.recent-blog * {
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
}
.recent-blog {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.55);
  margin: 10px 0;
  box-shadow: 1px 1px 2px 2px rgba(7, 111, 182, 0.036);
}
.recent-blog-image {
  min-height: 150px;
  max-height: 250px;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}
.recent-blog .blog-title {
  font-weight: 500;
  color: #076fb6;
  font-family: "Nunito", sans-serif;
  padding: 5px !important;
}
.recent-blog .blog-date {
  font-weight: 400;
  font-size: 0.8em;
  padding: 0 5px !important;
}

.sticky-side{
  position: sticky;
  top: 10%;
  height: 100vh;
  overflow-y: scroll;
  text-align: left;
}
</style>
