// store.js
import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

const api = axios.create({
    baseURL: "https://api.propertyinnepal.com.np/api/V1/",
    // baseURL: "http://localhost:8000/api/V1/",
});


function isEmpty(obj) {
    return JSON.stringify(obj) === JSON.stringify({});
}

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        banners: {},
        contact_details: {},
        testimonials: [],
        f_featured: [],
        f_premium: [],
        dynamic_shop: [],
        currentProperty: {},
        compare: [],
        topListings: [],
        achievements: [],
        hashtags: [],
        categories: [],
        about_data: {},
        home_about: {},
        faqs: [],
        blogs: [],
        blogPage: '',
        currentBlog: {},
        hashtagBlogs: [],
        categoryBlogs: [],
        recentBlogs: [],
        similarProperty: [],
        isLoading: true,
        teams:[],
        messageFromCEO:[],
    },
    mutations: {

        SET_BANNERS(state, data) {
            state.banners = data;
        },
        SET_TESTIMONIALS(state, data) {
            state.testimonials = data;
        },
        SET_CONTACT_DETAILS(state, data) {
            state.contact_details = data;
        },
        SET_FRONT_END_FEATURED(state, data) {
            state.f_featured = data;
        },
        SET_FRONT_END_PREMIUM(state, data) {
            state.f_premium = data;
        },
        SET_LAND_SALE(state, data) {
            state.land_sale = data;
        },
        SET_LAND_RENT(state, data) {
            state.land_rent = data;
        },
        SET_HOUSE_SALE(state, data) {
            state.house_sale = data;
        },
        SET_HOUSE_RENT(state, data) {
            state.house_rent = data;
        },
        SET_APARTMENT_SALE(state, data) {
            state.apartment_sale = data;
        },
        SET_APARTMENT_RENT(state, data) {
            state.apartment_rent = data;
        },
        SET_DATA(state, data) {
            state.dynamic_shop = data;
        },
        SET_CURRENT_PROPERTY(state, data) {
            state.currentProperty = data;
        },
        SET_SIMILAR_PROPERTY(state, data) {
            state.similarProperty = data;
        },
        SET_CURRENT_BLOG(state, data) {
            state.currentBlog = data;
        },
        SET_TOP_LISTINGS(state, data) {
            state.topListings = data;
        },
        SET_ACHIEVEMENTS(state, data) {
            state.achievements = data;
        },
        SET_BLOGS(state, data) {
            state.blogs = data;
        },
        SET_BLOG_PAGE(state, data) {
            state.blogPage = data;
        },
        PUSH_BLOGS(state, data) {
            state.blogs = [...state.blogs, ...data];
        },
        SET_RECENT_BLOGS(state, data) {
            state.recentBlogs = data;
        },
        SET_HASHTAGS(state, data) {
            state.hashtags = data;
        },
        SET_CATEGORIES(state, data) {
            state.categories = data;
        },

        SET_ABOUT_DATA(state, data) {
            state.about_data = data
        },
        SET_HOME_ABOUT(state, data) {
            state.home_about = data
        },
        SET_FAQS(state, data) {
            state.faqs = data
        },
        SET_HASHTAG_BLOGS(state, data) {
            state.hashtagBlogs = data
        },
        SET_CATEGORY_BLOGS(state, data) {
            state.categoryBlogs = data;
        },
        SET_TEAMS(state,data){
          state.teams = data;
        },
        SET_MESSAGE_FROM_CEO(state,data){
          state.messageFromCEO = data;
        },
        SET_COMPARE_PROPERTY(state, data) {
            if (state.compare.length >= 3) {
                state.compare.splice(0, 1);
            }
            state.compare.push(data);

        },

        setLoading(state, data) {
            state.isLoading = data;
        }
    },
    actions: {
        // Asynchronous operations and API calls go here
        fetchBanner({commit, state}) {
            if (isEmpty(state.banners)) {
                return api.get('/banners').then(response => {
                    if (response.data.data) {
                        commit('SET_BANNERS', response.data.data)
                        return response.data.data.images
                    }
                })
            }
        },
        fetchTestimonials({commit, state}) {
            if (state.testimonials.length < 1) {
                api.get('/testimonials').then(response => {
                    commit('SET_TESTIMONIALS', response.data.data)
                })
            }
        },
        fetchContactDetails({commit, state}) {
            if (isEmpty(state.contact_details)) {
                return api.get('/contact-details').then(response => {
                    if (response.data.data){
                        commit('SET_CONTACT_DETAILS', response.data.data)
                        if (response.data.data.logo) {
                            return response.data.data.logo;
                        }
                        else{
                            return false;
                        }
                    }
                    else{
                        return false;
                    }
                })
            }

        },
        // Front End Featured
        async fetchFrontEndFeatured({commit, state}) {
            if (state.f_featured.length < 1) {
                await api.get('/frontend-featured-properties').then(response => {
                    const responseData = response.data;
                    function popRemoveAndAssign(obj, key) {
                        // Clone the original object
                        const clonedObj = { ...obj };
                    
                        // Pop the element from the object
                        const poppedValue = clonedObj[key];
                    
                        // Remove the property from the cloned object
                        delete clonedObj[key];
                    
                        // Assign the popped element at the end of the cloned object
                        clonedObj[key] = poppedValue;
                    
                        // Return the modified cloned object
                        return clonedObj;
                    }
                    responseData.sale = popRemoveAndAssign(responseData.sale, 'land')
                    commit('SET_FRONT_END_FEATURED', responseData)
                })
            }
        },
        // Front End Premium
        async fetchFrontEndPremium({commit, state}) {
            if (state.f_premium.length < 1) {
                await api.get('/frontend-premium-properties').then(response => {
                    commit('SET_FRONT_END_PREMIUM', response.data.data)
                })
            }
        },
        // Front End Achievements
        fetchAchievements({commit, state}) {
            if (state.achievements.length < 1) {
                api.get('/achievements').then(response => {
                    commit('SET_ACHIEVEMENTS', response.data.data)
                })
            }
        },
        fetchFAQs({commit, state}) {
            if (state.faqs.length < 1) {
                api.get('/faqs').then(response => {
                    commit('SET_FAQS', response.data.data)
                })
            }
        },

        async fetchProperty({commit}, slug) {
            return await api.get('/property/' + slug).then(response => {
                commit('SET_CURRENT_PROPERTY', response.data.data);
                return response.data;
            })
        },
        async fetchSimilarProperty({commit}, slug) {
          return   api.post('/similar-property', {slug: slug}).then(response => {
                commit('SET_SIMILAR_PROPERTY', response.data.data)
                    return response.data.data;
            })
        },

        increaseViewProperty({state}) {
            api.post('/increase-views-property', {id:state.currentProperty.id});
        },
        fetchTopListing({commit, state}) {
            if (state.topListings.length < 1) {
                api.get('/top-listing-properties').then(response => {
                    commit('SET_TOP_LISTINGS', response.data.data)
                })
            }

        },

        fetchData({commit}, [what, type, page]) {
            commit('SET_DATA', []);
            commit('setLoading', true);
            return api.get('/filter-properties/' + what + '/' + type + '?page=' + page
            ).then((response) => {
                commit('setLoading', false);
                commit('SET_DATA', response.data.data);
                return response.data.meta;
            });
        },

        // BLOGS
        fetchAllBlogs({commit, state}, page) {

            if (state.blogPage !== page) {
                commit('SET_BLOG_PAGE', page);
                return api.get('/blogs' + '?page=' + page).then(response => {

                    if (page === 1) {
                        commit('SET_BLOGS', response.data.data)
                    } else {
                        commit('PUSH_BLOGS', response.data.data)
                    }

                    return response.data.meta;
                })
            }
        },
        fetchRecentBlogs({commit, state}) {
            if (state.recentBlogs.length < 1) {
                api.get('/recent-blogs').then(response => {
                    commit('SET_RECENT_BLOGS', response.data.data)
                })
            }

        },
        fetchBlog({commit}, slug) {
            commit('SET_CURRENT_BLOG', {})

            return api.get('/blog/' + slug).then(response => {
                if (response.data.message !== 'Blog Not Found') {
                    response.data.data.isLiked = false;
                    commit('SET_CURRENT_BLOG', response.data.data)
                }
                return response.data;

            })
        },
        increaseViewBlog({state}) {
            api.post('/blog/increment-views', {id: state.currentBlog.id});
        },
        increaseLikeBlog({state}) {
            api.post('/increase-likes-blog', {id:  state.currentBlog.id});
        },
        fetchBlogsByHashtag({commit}, [category, page]) {
            commit('setLoading', true);
            return api.get('/blogs-hashtag/' + category + '?page=' + page).then(response => {
                commit('SET_HASHTAG_BLOGS', response.data.data)
                commit('setLoading', false);
                return response.data.meta
            })
        },
        fetchBlogsByCategory({commit}, [hashtag, page]) {
            commit('setLoading', true);
            return api.get('/blogs-category/' + hashtag + '?page=' + page).then(response => {
                commit('SET_CATEGORY_BLOGS', response.data.data)
                commit('setLoading', false);
                return response.data.meta
            })
        },
        fetchHashtags({commit, state}) {
            if (state.hashtags.length < 1) {
                api.get('/blog-hashtags').then(response => {
                    commit('SET_HASHTAGS', response.data.data)
                })
            }
        },
        fetchCategories({commit, state}) {
            if (state.categories.length < 1) {
                api.get('/blog-categories').then(response => {
                    commit('SET_CATEGORIES', response.data.data)
                })
            }
        },

        fetchHomeAbout({commit, state}) {
            if (isEmpty(state.home_about)) {
                api.get('/home-about').then(response => {
                    commit('SET_HOME_ABOUT', response.data.data);
                })
            }
        },
        fetchTeam({commit}) {
            // if (isEmpty(state.teams)) {
                api.get('/team').then(response => {
                    commit('SET_TEAMS', response.data.data);
                })
            // }
        },
        fetchMessageFromCEO({commit}) {
            // if (isEmpty(state.messageFromCEO)) {
                api.get('/message-from-ceo').then(response => {
                    commit('SET_MESSAGE_FROM_CEO', response.data.data);
                })
            // }
        },
        fetchAboutData({commit, state}) {
            if (isEmpty(state.about_data)) {
                api.get('/about-data').then(response => {
                    commit('SET_ABOUT_DATA', response.data.data);
                })
            }
        },
        async searchProperty({commit}, data) {
            commit('setLoading', true);
            return await api.post('/search?page=' + data.page, data).then(response => {
                commit('SET_DATA', response.data.data);
                commit('setLoading', false);
                return response.data.meta

            })
        },
        compareProperties({commit}, data) {
            commit('SET_COMPARE_PROPERTY', data);
        }

    },
    getters: {
        // Computed properties based on the state go here
        getFrontendFeatured: (state) => () => {
            // Replace this with your actual method to fetch a post by slug from your backend or data source
            return state.f_featured;
        },

    },
});
