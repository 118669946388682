<template>
      <router-view/>
</template>

<script>
export default {
  name: 'AppLayout',
  components: {},
  data: () => ({
    //
  }),
};
</script>
