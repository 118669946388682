<template>
  <router-link :to="{ name: 'singleProperty', params: { slug: item.slug } }" class="normalizeLink w-100">
    <v-col class="col-12 rv-property-card">

      <v-row>

        <div class="col-12 rv-property-image">


          <div class="rv-overlay rv-property-image-bg-container">
            <div class="rv-property-image-bg" :style="$inlineBgImage(item.images[0])">

            </div>
          </div>

          <span class="rv-overlay rv-property-tag">
                    {{ item.for }}
                </span>
          <span title="Views" class="rv-overlay rv-property-views">
                    <v-icon small> mdi-eye</v-icon>
                    {{ item.views }}
                </span>

          <span class="rv-overlay rv-property-code" title="Compare">
                    <v-icon style="color:white;"
                            @click.prevent="goToPropertyCompare(item)">mdi-compare-horizontal</v-icon>
                </span>

        </div>

        <div class="col-12 rv-property-info">
          <p class="rvs-title" :title="item.name">
            {{ item.name }}
          </p>
          <v-row>
            <div class="col-md-4 col-12 p-p-p-l">
                        {{ item.location }}

            </div>
              <div class="col-md-3 col-12 p-p-p-code">
                <span>
                        {{ item.code }}
                </span>

            </div>
              <div class="col-md-5 col-12 p-p-p-d">
                                      {{ item.created_at_human }}

            </div>
              <!--                    <span class="rvs-location " :title="item.location">-->
              <!--                        <v-icon class="pin-icon"> mdi-map-marker</v-icon>-->
              <!--                        {{ item.location }}-->
              <!--                    </span>-->
              <!--            <span class="rvs-time ">-->
              <!--                        {{ item.created_at_human }}-->
              <!--                    </span>-->
              <!--            <span class="rvs-pin">-->
              <!--              {{item.code}}-->
              <!--            </span>-->

          </v-row>
        </div>


        <div class="flex-row">
          <div v-for="(feature, index) in item.features" :key="index" class="flex-item">
            <div :title="capitalizeText(feature.name)" class="text-center"
                 v-if="feature.value.length < 12  && index< 3 ">
              <img :src="feature.icon" :alt="feature.name" height="18" width="26">
              <span style="vertical-align: text-bottom;">{{ feature.value.trim(0, 1) }}</span>
            </div>
          </div>
        </div>


        <div class="col-12 py-0">
                <span class="property-for">
                  <span v-if="item.on_calling == '1'">On Call</span>
                  <span v-else> NPR. {{ $textifyPrice(item.price) }}</span>
                  <v-icon v-if="item.is_premium !=0" class="overlay-crown">mdi-crown</v-icon>
              </span>
        </div>
      </v-row>
    </v-col>
  </router-link>
</template>

<script>
export default {
  name: "PropertyCard",
  props: {
    item: {
      required: true, // Set to true if the prop is mandatory
    },
    // Add more props as needed
  },
  methods: {
    capitalizeText(text) {
      if (!text) return '';
      return text.toLowerCase().replace(/(^|\s)\S/g, function (char) {
        return char.toUpperCase();
      });
    },

    goToPropertyCompare(item) {
      this.$store.dispatch('compareProperties', item).then(() => {
        this.$router.push({name: "compareProperty"});
      })
    },
  }
}
</script>

<style scoped>
.property-for {
  float: right;
  background-color: #07006B;
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.rv-property-card {

  padding: 24px;
  gap: 16px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid #00BCE2;
  background: #FFF;
  box-shadow: 0 4px 28px -16px #07006B;

  max-width: 100%;
  display: block;
  overflow: hidden;

}

.rv-property-image {
  min-height: 200px !important;
  background-position: center;
  background-size: cover;
  width: 100%;
}

/*.rv-property-card{*/
.rv-property-image {
  position: relative;
}

.rv-overlay {
  position: absolute;

}

/*.rv-property-image-bg{*/
/*  top: 0;*/
/*  left: 0;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  background-position: center;*/
/*  background-size:cover;*/
/*  overflow: hidden !important;*/
/*  z-index: 0;*/

/*}*/
/*.rv-property-image:hover .rv-property-image-bg{*/
/*  transform: scale(1.15);*/
/*  animation: grow  ease-in-out 0.5s alternate;*/
/*}*/

/*@keyframes grow {*/
/*  0% {*/
/*    transform: scale(1);*/
/*  }*/
/*  100% {*/
/*    transform: scale(1.15);*/
/*  }*/
/*}*/

.rv-property-image-bg {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden !important;
  z-index: 0;
  transition: all .5s !important;

}

.rv-property-image:hover .rv-property-image-bg,
.rv-property-image:focus .rv-property-image-bg {
  transform: scale(1.15);
  /*animation: grow ease-in-out 0.5s;*/
}


.rv-property-image-bg::before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rv-property-image:hover .rv-property-image-bg:before,
.rv-property-image:focus .rv-property-image-bg:before {
  display: block;
}



.rv-property-image .rv-property-image-bg { /* added selector for when not hovering */
  transform: scale(1);
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
}


.rv-property-info {
  z-index: 5;
}


.rv-property-tag {
  top: 8px;
  left: 8px;
  padding: 5px;
  font-size: 0.85em;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  background: linear-gradient(red, #ab0000, red);
  border-radius: 7px;
}

.rv-property-views {
  top: 8px;
  right: 8px !important;
  background-color: #399ece;
  padding: 5px;
  font-size: 0.85em;
  color: white;
  border-radius: 7px;

}

.rv-property-views * {
  color: white;
}

.v-icon.overlay-crown {
  color: red !important;
}

.overlay-crown {
  position: absolute;
  right: -10px;
  top: -10px;
  rotate: 45deg;
}

.rv-property-crown {
  top: 8px;
  right: 8px !important;
}

.rv-property-crown * {
  font-size: 1.9em;
  color: red !important;
}

.rv-property-code {
  bottom: -8px;
  right: 8px !important;
  background-color: #399ece;
  padding: 5px;
  font-size: 0.85em;
  color: white;
  border-radius: 7px;

}

.rvs-title {
  color: #000;

  /* Heading/H4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 7px !important;
  max-width: 100%;


  text-overflow: ellipsis; /* will make [...] at the end */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 3px;

}

.pin-icon {
  color: #00bbe4 !important;
  font-size: 16px !important;
}


.rv-property-image-bg-container {
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
}

.rvs-location {
  color: #525252;

  /* Heading/S */
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 15.6px */
  float: left;
  text-transform: capitalize;
  max-width: 70%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.rvs-time {
  color: #4f4e4e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  float: right;
  font-family: Roboto;

}
.rvs-pin {
  color: #4f4e4e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  float: right;
  font-family: Roboto;

}

.rvs-price-btn {
  border-radius: 4px;
  background: #07006B !important;
  color: white;
}

.rvs-compare-btn {
  border-radius: 4px;
  background: #399ece !important;
  color: white;
}

.w-100 {
  max-width: 100%;
}


.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flex-item {
  flex-basis: auto;
  flex-grow: 1;
  text-align: center;
  padding: 5px;
}



.p-p-p-l{
  color: #525252;

  /* Heading/S */
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 15.6px */
  float: left;
  text-transform: capitalize;
  max-width: 70%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-p-p-d{
  color: #4f4e4e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Roboto;
  float: right;
  text-align: right;

}
.p-p-p-code{
  color: #4f4e4e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Roboto;
}
.p-p-p-code span{
  padding: 5px;
  background-color: #399ece;
  color: white;
  border-radius: 4px;
  font-size: 13px;
}




</style>
