import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
  config: { id: '406094633' }
})

// import '@/assets/main.scss';
require('@/assets/main.scss');

Vue.use(VueMeta);


import VueProgressBar from 'vue-progressbar';

Vue.use(VueProgressBar, {
    color:'#399ece',
    failedColor: 'red',
    thickness: '5px',
})


import VuePaginate from "vue-paginate";
Vue.use(VuePaginate);



import Notifications from 'vue-notification'
Vue.use(Notifications)



import axios from "axios";

//TODO: Change before PR
const api = axios.create({
    baseURL: "https://api.propertyinnepal.com.np/api/V1/",
    // baseURL: "http://localhost:8000/api/V1/",
});

Vue.prototype.$axios = api;

Vue.config.productionTip = false;

// Price To Text Crore and Lakhs
Vue.prototype.$textifyPrice = function (value) {
    const factors = {
        10000000: 'Cr',
        100000: 'L'
    };

    const factor = Object.keys(factors)
        .sort((a, b) => b - a)
        .find(key => value >= parseInt(key));

    if (factor) {
        const formatted = (value / factor).toFixed(2);
        if (Number.isInteger(parseFloat(formatted))) {
            return formatted.split('.')[0] + ` ${factors[factor]}`;
        } else {
            return formatted + ` ${factors[factor]}`;
        }
    }
    return this.$formatPrice(value);
}
Vue.prototype.$longTextifyPrice = function(value) {

    const factors = {
        10000000: 'Cr',
        100000: 'L',
        1000:'Th ',
    };

    let remainingValue = value;
    let text = '';

    Object.keys(factors).sort((a, b) => b - a).forEach(key => {
        const factor = parseInt(key);
        if (remainingValue >= factor) {
            const amount = Math.floor(remainingValue / factor);
            remainingValue = remainingValue % factor;
            text += amount + '' + factors[key] + ' ';
        }
    });

    text = text.trim();

    if (remainingValue > 0) {
        text += ' and ' + remainingValue;
    }

    return text;
}


// Inline Background Image Function
Vue.prototype.$inlineBgImage = function (src) {

    if (src && src !== "") {
        if (src.includes("http")) {
            return {
                backgroundImage: `url("${src}")`,
                transition: "background-image 1s ease-in-out",
            };
        } else {
            try {
                let fileExt = src.substring(src.lastIndexOf("."));
                src = src.replace("/img/", "");
                src = src.replace(fileExt, "");

                let bgImage = require(`./assets/img/${src}${fileExt}`);
                return {
                    backgroundImage: `url("${bgImage}")`,
                    transition: "background-image 1s ease-in-out",
                };
            } catch (error) {
                return {
                    background: `black`,
                    transition: "background 1s ease-in-out",
                };
            }
        }
    } else {
        return {
            background: `black`,
            transition: "background 1s ease-in-out",
        };
    }
};

Vue.prototype.$formatPrice = function (price) {
    price = String(price);
    let formattedPrice;

    // Formatting last three digits
    if (price.length <= 3) {
        formattedPrice = price;
    } else {
        formattedPrice = price.slice(-3);
    }

    // Formatting remaining digits in groups of two
    let remainingDigits = price.slice(0, -3);
    while (remainingDigits.length > 0) {
        formattedPrice = remainingDigits.slice(-2) + "," + formattedPrice;
        remainingDigits = remainingDigits.slice(0, -2);
    }
    return formattedPrice;
};

router.beforeEach(async function (to, from, next) {
        if (to.path !== from.path){
            Vue.prototype.$Progress.start()
        }

    await store.dispatch('fetchContactDetails').then(response => {
        if (response) {
            const img = new Image();
            img.src = response
        }
    });
    store.dispatch('fetchFrontEndFeatured');
    store.dispatch('fetchFrontEndPremium');
    store.dispatch('fetchTopListing');


    const fromIsShop = from.name === 'shop';
    const toIsShop = to.name === 'shop';

    if (!fromIsShop || !toIsShop) {
        window.scrollTo(0, 0);
    }

    await next();


});

new Vue({
    router,
    store,
    vuetify,
    titleTemplate: "%s - Property In Nepal",
    render: (h) => h(App),
}).$mount("#app");

